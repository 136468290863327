import Text from '@commons/Text'
import adminRoutesConstant from '@constants/adminRoutesConstant'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const PageNotFound = () => {
	const auth = useSelector((state) => state?.auth)
	const location = useLocation()
	const navigate = useNavigate()

	const queryParameters = new URLSearchParams(window.location.search)
	const access_token = queryParameters.get('access-token')

	if (!auth.token.info && !auth?.user?.info?.role && adminRoutesConstant.routesByUrls[location.pathname]) {
		return null
	} else if (access_token) {
		navigate(adminRoutesConstant.adminPaths.DashboardScreen.url(), {
			replace: true
		})
	}

	return (
		<div
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				position: 'absolute',
				width: '100%',
				height: '100%',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%,-50%)',
				zIndex: 0
			}}
		>
			<Box mb={3}>
				<Text variant='h1'>Oops! Page Not Found (Error: 404)</Text>
			</Box>

			<Box align='center'>
				<Text variant='h2' align='center'>
					You might want to visit -
					<Text
						variant='h2'
						ascent='secondary'
						component={Link}
						to={
							auth.token?.info
								? adminRoutesConstant.adminPaths.DashboardScreen.url()
								: adminRoutesConstant.adminPaths.LoginScreen.url()
						}
					>
						{auth.token?.info ? 'Dashboard Page' : 'Login Page'}
					</Text>
				</Text>
			</Box>
		</div>
	)
}

export default PageNotFound
