import axios from 'axios'
import ApiService from './ApiService'

axios.defaults.timeout = 120000

const schema = window.location.protocol

// const baseURL =
// 	import.meta.env.VITE_APP_ENABLE_ONPREM === 'true' ? `${schema}//${window.location.host}` : import.meta.env.VITE_APP_BASE_API_URL

const baseURL = import.meta.env.VITE_APP_ENABLE_ONPREM === 'true' ? `${schema}//${window.location.host}` : import.meta.env.VITE_VRM_API_URL

// export const baseURL =
//   import.meta.env.VITE_APP_BASE_API_URL || "https://devapi.zeron.one";

const reportGeneratorURL = import.meta.env.VITE_APP_PDF_REPORT_URL

const Axios = axios.create({
	baseURL
})

export const AxiosService = new ApiService({
	baseURL: baseURL
})

export const DrmService = new ApiService({
	//baseURL:'https://drmdevapi.zeron.one'
	baseURL: import.meta.env.VITE_DRM_API_URL
})

export const ASMOpenSearchv2 = new ApiService({
	baseURL: import.meta.env.VITE_APP_ASM_OPEN_SEARCH
})

export const SecurityReportService = new ApiService({
	baseURL: import.meta.env.VITE_APP_SECURITY_REPORT_URL
	// withCredentials: true,
})

export const SamService = new ApiService({
	// baseURL: "https://asm.zeron.one"
	baseURL: import.meta.env.VITE_APP_SAM_API_URL
})

export const ReportGeneratorService = new ApiService({
	baseURL: reportGeneratorURL
})

export const BuyZeronApi = new ApiService({
	baseURL: import.meta.env.VITE_APP_BUY_EVENT_URL
})

export const CspmService = new ApiService({
	baseURL: import.meta.env.VITE_CSPM_URL
})

export const MitreAttackService = new ApiService({
	baseURL: 'https://atk.zeron.one'
})

export const CrqService = new ApiService({
	baseURL: 'https://crq.zeron.one'
})

export const ConnectorService = new ApiService({
	baseURL: import.meta.env.VITE_API_CONNECTOR_URL
})

export const RCVarZeronService = new ApiService({
	baseURL: 'https://rcvar.zeron.one'
})

export const ApiConnectorService = new ApiService({
	baseURL: import.meta.env.VITE_API_CONNECTOR_URL
})

export const VRMService = new ApiService({
	baseURL: import.meta.env.VITE_VRM_API_URL
})

export default Axios
