import loadable, { lazy } from '@loadable/component'
import { convertRouteNamesToLabel, getUuid, mapNestedRoutes, replaceVariableWithValueInURL } from '@utils/other'
import { SidebarListKeyConstants } from './SidebarOrderedListConstant'
import { permissionKeys } from './permissions'
//-------------------------------Config Files ------------------------------------------------------------------------------------------------------
const IndexRoute = loadable(() => import(/* webpackChunkName: "IndexRoute" */ '@routes/IndexRoute'))
const LoginScreen = loadable(() => import(/* webpackChunkName: "LoginScreen" */ '@screens/Auth/LoginScreen'))
const RegistrationScreen = loadable(() => import(/* webpackChunkName: "LoginScreen" */ '@screens/Auth/RegisterScreen'))
const LoginWithMfaScreen = loadable(() => import(/* webpackChunkName: "LoginScreen" */ '@screens/Auth/LoginWithMfaScreen'))
const ForgotPasswordScreen = loadable(() => import(/* webpackChunkName: "ForgotPasswordScreen" */ '@screens/Auth/ForgotPasswordScreen'))
const ResetPasswordScreen = loadable(() => import(/* webpackChunkName: "ResetPasswordScreen" */ '@screens/Auth/ResetPasswordScreen'))
const TokenVerficationScreen = loadable(() =>
	import(/* webpackChunkName: "TokenVerficationScreen" */ '@screens/Auth/TokenVerficationScreen')
)
const UnAuthorizedScreen = loadable(() => import(/* webpackChunkName: "UnAuthorizedScreen" */ '@screens/UnAuthorizedScreen'))
const BillingScreen = lazy(() => import(/* webpackChunkName: "BillingScreen" */ '@components/vrm/screens/Billing/BillingScreen'))
const MyAccountScreen = lazy(() => import(/* webpackChunkName: "MyAccountScreen" */ '@components/vrm/screens/MyAccount/MyAccountScreen'))
const NotificationsScreen = lazy(() =>
	import(/* webpackChunkName: "NotificationsScreen" */ '@components/vrm/screens/MyAccount/NotificationsScreen')
)
const FeedbackScreen = lazy(() => import(/* webpackChunkName: "FeedbackScreen" */ '@screens/Feedback/FeedbackScreen'))
const InternalTicketScreen = lazy(() => import(/* webpackChunkName: "InternalTicketScreen" */ '@screens/Ticket/InternalTicketScreen'))
const PdfReportScreen = lazy(() => import(/* webpackChunkName: "PdfReportScreen" */ '@screens/PdfReport/PdfReportScreen'))
const CreatePdfReportScreen = lazy(() => import(/* webpackChunkName: "CreatePdfReportScreen" */ '@screens/PdfReport/CreatePdfReportScreen'))
//------------------------Vendor Screen -------------------------------------------------------------------------------------------------------
const VendorVRMScreen = loadable(() => import('@components/vendor-vrm/VendorVRM'))
const VendorLandingScreen = loadable(() => import('@components/vendor-vrm/VendorLanding'))
// ---------------------- VRM IMPORTS STARTS --------------------------------------------------------------------------------------------------
const DashboardScreen = lazy(() => import('@components/vrm/screens/Dashboard/Dashboard'))
const ProcessCriticality = lazy(() => import('@components/vrm/screens/Process Overview/ProcessCriticality/ProcessCriticality'))
const ProcessCriticalityQuest = lazy(() => import('@components/vrm/screens/Process Overview/Process/TerminatePage'))
const ServiceDescription = lazy(() => import('@components/vrm/screens/Process Overview/ProcessCriticality/ServiceDescription'))
const VendorSelection = lazy(() => import('@components/vrm/screens/Process Overview/VendorSelection/VendorSelection'))
const VendorContracting = lazy(() => import('@components/vrm/screens/Process Overview/VendorSelection/VendorContracting'))
const VendorOnboarding = lazy(() => import('@components/vrm/screens/Process Overview/VendorOnboarding/VendorOnboarding'))
const AccessForm = lazy(() => import('@components/vrm/screens/Process Overview/VendorOnboarding/AccessForm'))
const VendorRiskAssessment = lazy(() => import('@components/vrm/screens/Process Overview/VendorRiskAssessment/VendorRiskAssessment'))
const VendorScoping = lazy(() => import('@components/vrm/screens/Process Overview/VendorRiskAssessment/VendorScoping'))
const VendorDetailedAssessment = lazy(() => import('@components/vrm/screens/Process Overview/VendorRiskAssessment/VendorDetailedAssesment'))
const VendorRiskMonitoring = lazy(() => import('@components/vrm/screens/Process Overview/VendorRiskMonitoring/VendorRiskMonitoring'))
const ProcessOverview = lazy(() => import('@components/vrm/screens/Process Overview/ProcessOverview'))
const Vendors = lazy(() => import('@components/vrm/screens/Vendors/Vendors'))
const VendorAddition = lazy(() => import('@components/vrm/screens/Vendors/Vendor Action/VendorAddition'))
const VendorDetails = lazy(() => import('@components/vrm/screens/Vendors/Vendor Details/VendorDetails'))
const VendorView = lazy(() => import('@components/vrm/screens/Vendors/Vendor Details/VendorDetailsMain'))
const RiskManagerQuestionnaire = lazy(() => import('@components/vrm/screens/Process Overview/Questionnaire/RiskManagerQuestionnaire'))
const AdministrationOverview = lazy(() => import('@components/vrm/screens/Administration/AdministrationOverview'))
const MasterFeed = lazy(() => import('@components/vrm/screens/Master Feed/MasterFeed'))
// const MasterQuestionnaire = lazy(() => import('@components/vrm/screens/Process Overview/Questionnaire/RiskManagerQuestionnaire'))
const ChecklistScreen = lazy(() => import('@components/vrm/screens/ChecklistBuilder/ChecklistBuilder'))
const VendorRiskProfiling = lazy(() => import('@components/vrm/screens/Process Overview/VendorRiskProfiling/VendorRiskProfiling'))
const VendorTermination = lazy(() => import('@components/vrm/screens/Process Overview/VendorTermination/VendorTermination'))
const Questionnaire = lazy(() => import('@components/vrm/screens/Process Overview/Questionnaire/RiskManagerQuestionnaire'))
// ---------------------- VRM IMPORTS ENDS -----------------------------

// Constants
export const GUARD = 'GUARD'
export const BASE = 'BASE'
export const AUTH = 'AUTH'
export const UNLICENSED = 'UNLICENSED'

export const routeTypes = {
	GUARD,
	BASE
}

export const crudType = {
	canCreate: 'canCreate',
	canRead: 'canRead',
	canUpdate: 'canUpdate',
	canDelete: 'canDelete'
}

export function GeneratePathForRoutes(
	permissionKey,
	crudType,
	url,
	path,
	type,
	component,
	props = {},
	config = {
		key: null,
		label: null,
		isShowOnSidebar: false,
		Icon: null,
		hideItem: false,
		order: 0
	}
) {
	this.permissionKey = permissionKey
	this.crudType = crudType
	this.url = function (values = []) {
		return values.length <= 0 ? url : replaceVariableWithValueInURL(url, values)
	}
	this.path = path
	this.type = type
	this.label = config.label
	this.isShownOnSidebar = config.isShownOnSidebar
	this.hideItem = config.hideItem
	this.parentRoute = config.parentRoute
	this.doNotShowOnDashboardLayout = config.doNotShowOnDashboardLayout
	this.key = config.key || getUuid()
	this.routeName = config.routeName
	this.component = component
	this.props = props
	this.Icon = config.Icon
	this.order = config.order
}

const adminPaths = {
	// Public routes
	IndexRoute: new GeneratePathForRoutes(null, null, `/`, `/`, BASE, <IndexRoute />, { exact: true }, { routeName: 'IndexRoute' }),
	LoginWithMfaScreen: new GeneratePathForRoutes(
		null,
		null,
		`/login/mfa`,
		`/login/mfa`,
		AUTH,
		<LoginWithMfaScreen />,
		{},
		{ routeName: 'LoginWithMfaScreen' }
	),
	LoginScreen: new GeneratePathForRoutes(null, null, `/login`, `/login`, AUTH, <LoginScreen />, {}, { routeName: 'LoginScreen' }),
	RegistrationScreen: new GeneratePathForRoutes(
		null,
		null,
		`/register`,
		`/register`,
		AUTH,
		<RegistrationScreen />,
		{},
		{ routeName: 'RegistrationScreen' }
	),

	VendorLandingScreen: new GeneratePathForRoutes(
		null,
		null,
		`/vendor-view`,
		`/vendor-view`,
		AUTH,
		<VendorLandingScreen />,
		{},
		{ routeName: 'Vendor View' }
	),
	VendorVRMScreen: new GeneratePathForRoutes(
		null,
		null,
		`/vendor-vrm`,
		`/vendor-vrm`,
		AUTH,
		<VendorVRMScreen />,
		{},
		{ routeName: 'Vendor View Only' }
	),

	BusinessRiskPostureScreenParent: new GeneratePathForRoutes(
		null,
		null,
		`/business-posture-parent`,
		`/business-posture-parent`,
		GUARD,
		<></>,
		{},
		SidebarListKeyConstants.BusinessRiskPostureScreenParent
	),

	CreatePdfReportScreen: new GeneratePathForRoutes(
		null,
		null,
		`/pdf-report`,
		`/pdf-report`,
		GUARD,
		<CreatePdfReportScreen />,
		{},
		{ routeName: 'CreatePdfReportScreen', label: 'Reports' }
	),
	PdfReportScreen: new GeneratePathForRoutes(
		null,
		null,
		`/pdf-report/create`,
		`/pdf-report/create`,
		GUARD,
		<PdfReportScreen />,
		{},
		{ doNotShowOnDashboardLayout: true, routeName: 'PdfReportScreen' }
	),
	UnAuthorizedScreen: new GeneratePathForRoutes(
		null,
		null,
		`/unauthorized`,
		`/unauthorized`,
		BASE,
		<UnAuthorizedScreen />,
		{},
		{ doNotShowOnDashboardLayout: false, routeName: 'SecurityReportScreen' }
	),

	ForgotPasswordScreen: new GeneratePathForRoutes(
		null,
		null,
		`/forgotPassword`,
		`/forgotPassword`,
		AUTH,
		<ForgotPasswordScreen />,
		{},
		{ routeName: 'ForgotPasswordScreen' }
	),
	ResetPasswordScreen: new GeneratePathForRoutes(
		null,
		null,
		`/reset/password`,
		`/reset/password`,
		AUTH,
		<ResetPasswordScreen />,
		{},
		{ routeName: 'ForgotPasswordScreen' }
	),
	TokenVerficationScreen: new GeneratePathForRoutes(
		null,
		null,
		`/verify`,
		`/verify`,
		AUTH,
		<TokenVerficationScreen />,
		{},
		{ routeName: 'ForgotPasswordScreen' }
	),
	// Protected routes
	//VRM Routes
	DashboardScreen: new GeneratePathForRoutes(
		null,
		null,
		`/dashboard`,
		'/dashboard',
		GUARD,
		<DashboardScreen />,
		{ exact: true },
		SidebarListKeyConstants.DashboardScreen
	),
	ProcessVRM: new GeneratePathForRoutes(
		null,
		null,
		`/process`,
		'/process',
		GUARD,
		<ProcessOverview />,
		{ exact: true },
		SidebarListKeyConstants.ProcessVRM
	),
	ManageVendor: new GeneratePathForRoutes(
		null,
		null,
		`/vendors`,
		'/vendors',
		GUARD,
		<Vendors />,
		{ exact: true },
		SidebarListKeyConstants.ManageVendor
	),
	AdministrationOverView: new GeneratePathForRoutes(
		null,
		null,
		`/administration`,
		'/administration',
		GUARD,
		<AdministrationOverview />,
		{ exact: true },
		SidebarListKeyConstants.AdministrationOverView
	),
	MasterFeed: new GeneratePathForRoutes(
		null,
		null,
		`/master-feed`,
		'/master-feed',
		GUARD,
		<MasterFeed />,
		{ exact: true },
		SidebarListKeyConstants.MasterFeed
	),
	// MasterQuestionnaire: new GeneratePathForRoutes(
	// 	null,
	// 	null,
	// 	`/master-questionnaire`,
	// 	'/master-questionnaire',
	// 	GUARD,
	// 	<MasterQuestionnaire />,
	// 	{ exact: true },
	// 	SidebarListKeyConstants.MasterQuestionnaire
	// ),
	ChecklistScreen: new GeneratePathForRoutes(
		null,
		null,
		`/checklist-questionnaire`,
		`/checklist-questionnaire`,
		GUARD,
		<ChecklistScreen />,
		{ exact: true },
		SidebarListKeyConstants.ChecklistScreen
	),
	[permissionKeys.VendorAddition]: new GeneratePathForRoutes(
		null,
		null,
		`/vendors/add-vendor`,
		'/vendors/add-vendor',
		GUARD,
		<VendorAddition />,
		{ exact: true },
		{ label: 'Add new Vendor' }
	),
	[permissionKeys.VendorView]: new GeneratePathForRoutes(
		null,
		null,
		`/vendors/vendor-view`,
		'/vendors/vendor-view',
		GUARD,
		<VendorView />,
		{ exact: true },
		{ label: 'Vendor Overview' }
	),
	[permissionKeys.VendorDetails]: new GeneratePathForRoutes(
		null,
		null,
		`/vendors/vendor-details`,
		'/vendors/vendor-details',
		GUARD,
		<VendorDetails />,
		{ exact: true },
		{ label: 'Vendor Details' }
	),
	[permissionKeys.ProcessCriticality]: new GeneratePathForRoutes(
		null,
		null,
		`/process-criticality`,
		'/process-criticality',
		GUARD,
		<ProcessCriticality />,
		{ exact: true },
		{ label: 'Process Details' }
	),
	[permissionKeys.RiskManagerQuest]: new GeneratePathForRoutes(
		null,
		null,
		`vendors/vendor-view/risk-manager-quest`,
		'vendors/vendor-view/risk-manager-quest',
		GUARD,
		<RiskManagerQuestionnaire />,
		{ exact: true },
		{ label: 'Risk Manager Quest' }
	),
	[permissionKeys.ProcessCriticalityQuest]: new GeneratePathForRoutes(
		null,
		null,
		`/process-terminate`,
		'/process-terminate',
		GUARD,
		<ProcessCriticalityQuest />,
		{ exact: true },
		{ label: 'Vendor Exit Form' }
	),
	[permissionKeys.ServiceDescription]: new GeneratePathForRoutes(
		null,
		null,
		`/service-description`,
		'/service-description',
		GUARD,
		<ServiceDescription />,
		{ exact: true },
		{ label: 'Service Description' }
	),
	[permissionKeys.VendorSelection]: new GeneratePathForRoutes(
		null,
		null,
		`/vendor-selection`,
		'/vendor-selection',
		GUARD,
		<VendorSelection />,
		{ exact: true },
		{ label: 'Vendor Selection' }
	),
	[permissionKeys.VendorContracting]: new GeneratePathForRoutes(
		null,
		null,
		`/vendor-contracting`,
		'/vendor-contracting',
		GUARD,
		<VendorContracting />,
		{ exact: true },
		{ label: 'Vendor Contracting' }
	),
	[permissionKeys.VendorOnboarding]: new GeneratePathForRoutes(
		null,
		null,
		`/vendor-onboarding`,
		'/vendor-onboarding',
		GUARD,
		<VendorOnboarding />,
		{ exact: true },
		{ label: 'Vendor Onboarding' }
	),
	[permissionKeys.AccessForm]: new GeneratePathForRoutes(
		null,
		null,
		`/access-form`,
		'/access-form',
		GUARD,
		<AccessForm />,
		{ exact: true },
		{ label: 'Access Form' }
	),
	[permissionKeys.VendorRiskProfiling]: new GeneratePathForRoutes(
		null,
		null,
		`/vendor-risk-profiling`,
		'/vendor-risk-profiling',
		GUARD,
		<VendorRiskProfiling />,
		{ exact: true },
		{ label: 'Vendor Categorization' }
	),
	[permissionKeys.VendorRiskMonitoring]: new GeneratePathForRoutes(
		null,
		null,
		`/vendor-risk-monitoring`,
		'/vendor-risk-monitoring',
		GUARD,
		<VendorRiskMonitoring />,
		{ exact: true },
		{ label: 'Risk Monitoring' }
	),
	[permissionKeys.VendorRiskAssessment]: new GeneratePathForRoutes(
		null,
		null,
		`/vendor-risk-assessment`,
		'/vendor-risk-assessment',
		GUARD,
		<VendorRiskAssessment />,
		{ exact: true },
		{ label: 'Vendor Risk Assessment' }
	),
	[permissionKeys.VendorScoping]: new GeneratePathForRoutes(
		null,
		null,
		`/vendor-scoping`,
		'/vendor-scoping',
		GUARD,
		<VendorScoping />,
		{ exact: true },
		{ label: 'Vendor Scoping' }
	),
	[permissionKeys.VendorDetailedAssessment]: new GeneratePathForRoutes(
		null,
		null,
		`/vendor-detailed-assessment`,
		'/vendor-detailed-assessment',
		GUARD,
		<VendorDetailedAssessment />,
		{ exact: true },
		{ label: 'Vendor Detailed Assessment' }
	),
	[permissionKeys.VendorTermination]: new GeneratePathForRoutes(
		null,
		null,
		`/VendorTermination`,
		'/VendorTermination',
		GUARD,
		<VendorTermination />,
		{ exact: true },
		{ label: 'Vendor Termination' }
	),
	[permissionKeys.QuestionnaireBuilder]: new GeneratePathForRoutes(
		null,
		null,
		`/questionnaire`,
		'/questionnaire',
		GUARD,
		<Questionnaire />,
		{ exact: true },
		{ label: 'Questionnaire' }
	),
	[permissionKeys.VendorRiskProfiling]: new GeneratePathForRoutes(
		null,
		null,
		`/vendor-risk-profiling`,
		'/vendor-risk-profiling',
		GUARD,
		<VendorRiskProfiling />,
		{ exact: true },
		{ label: 'Vendor Risk Profiling' }
	),

	BillingScreen: new GeneratePathForRoutes(null, null, `/billing`, `/billing`, GUARD, <BillingScreen />, {}),
	MyAccountScreen: new GeneratePathForRoutes(null, null, `/account`, `/account`, GUARD, <MyAccountScreen />, {}),
	NotificationsScreen: new GeneratePathForRoutes(null, null, `/notifications`, `/notifications`, GUARD, <NotificationsScreen />, {}),
	FeedbackScreen: new GeneratePathForRoutes(null, null, `/feedback`, `/feedback`, GUARD, <FeedbackScreen />, {}),
	InternalTicketScreen: new GeneratePathForRoutes(null, null, `/ticket`, `/ticket`, GUARD, <InternalTicketScreen />, {})
}

// Admin urls
// Assigning keys as routeNames to all
const allRoutesWithLabels = Object.entries(adminPaths).reduce((obj, [routeName, routeObject]) => {
	obj[routeName] = {
		...routeObject,
		routeName: routeName,
		label: routeObject.label ? routeObject.label : convertRouteNamesToLabel(routeName)
	}

	return obj
}, {})

const routesByUrls = Object.entries(allRoutesWithLabels).reduce((obj, [, routeObject]) => {
	obj[routeObject.url()] = { ...routeObject }
	return obj
}, {})

const allRouteUrls = Object.entries(allRoutesWithLabels).map(([, route]) => {
	return route.url()
})
const unlicensedRoutes = Object.values(allRoutesWithLabels).filter((each) => each.type === UNLICENSED)
const baseRoutes = Object.values(allRoutesWithLabels).filter((each) => each.type === BASE)
const authRoutes = Object.values(allRoutesWithLabels).filter((each) => each.type === AUTH)
const guardRoutes = Object.values(allRoutesWithLabels).filter((each) => each.type === GUARD)
const nestedRoutesByKey = mapNestedRoutes(allRoutesWithLabels)
const nestedRoutes = Object.assign(
	{},
	...Object.keys(nestedRoutesByKey).map((item) => ({
		[item]: Object.values(nestedRoutesByKey[item])
	}))
)

// TODO: REMOVE
// TODO: REMOVE

export default {
	guardRoutes,
	baseRoutes,
	authRoutes,
	unlicensedRoutes,
	adminPaths,
	allRoutesWithLabels,
	routesByUrls,
	allRouteUrls,
	nestedRoutes,
	nestedRoutesByKey,
	routeTypes
}
