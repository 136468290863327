export const permissionKeys = {
	// To enable cloud monitor in sidebar
	AwsScreen: 'AwsScreen',
	AzureScreen: 'AzureScreen',
	GcpScreen: 'GcpScreen',

	// To enable Business posture in sidebar
	BusinessRiskPostureScreen: 'BusinessRiskPostureScreen',
	BusinessRiskPostureFinancialFactorsTab: 'BusinessRiskPostureFinancialFactorsTab',
	BusinessRiskPostureFinancialsTab: 'BusinessRiskPostureFinancialsTab',
	RBIMandatesOverviewScreen: 'RBIMandatesOverviewScreen',
	RbiSarMandatesOverviewScreen: 'RbiSarMandatesOverviewScreen',
	RbiPapgMandatesOverviewScreen: 'RbiPapgMandatesOverviewScreen',
	SebiMandatesOverviewScreen: 'SebiMandatesOverviewScreen',
	CertInMandatesOverviewScreen: 'CertInMandatesOverviewScreen',
	IrdaiMandatesOverviewScreen: 'IrdaiMandatesOverviewScreen',

	// To enable Compliance in sidebar
	GdprScreen: 'GdprScreen',
	PciDssScreen: 'PciDssScreen',
	NistScreen: 'NistScreen',
	HippaScreen: 'HippaScreen',
	Soc2Screen: 'Soc2Screen',
	Iso27001Screen: 'Iso27001Screen',
	Iso270012022Screen: 'Iso270012022Screen',
	RmitScreen: 'RmitScreen',
	AuditReadinessScreen: 'AuditReadinessScreen',

	//to let user change the status from draft to publish
	PolicyApproval: 'PolicyApproval',

	//to anable industry benchamrks
	IndustryBenchmarks: 'IndustryBenchmarks',

	// To enable Others in sidebar
	IntegrationScreen: 'IntegrationScreen',
	Zensor: 'Zensor',
	AdministrationScreen: 'AdministrationScreen',
	HelpScreen: 'HelpScreen',

	// To enable Defence in sidebar
	RiskMitigationScreen: 'RiskMitigationScreen',
	IntegrationViewScreen: 'IntegrationViewScreen',
	SecurityEventsNestedScreen: 'SecurityEventsNestedScreen',
	HostNestedScreen: 'HostNestedScreen',
	DetectionNestedScreen: 'DetectionNestedScreen',
	IncidentResponseNestedScreen: 'IncidentResponseNestedScreen',
	CspmOverviewScreen: 'CspmOverviewScreen',
	AttackSurfaceVaptReports: 'AttackSurfaceVaptReports',

	// This permission is used to display the SSO setup component
	SSO: 'SSO',

	// ---------------------- VRM PERMISSIONS STARTS ---------------------------

	//Dashboard
	//DashboardScreen: 'DashboardScreen',

	//Process
	ProcessVRM: 'ProcessVRM',
	ProcessScreen: 'ProcessScreen',
	ProcessCriticality: 'ProcessCriticality',
	ProcessCriticalityQuest: 'ProcessCriticalityQuest',
	ServiceDescription: 'ServiceDescription',
	VendorSelection: 'VendorSelection',
	VendorContracting: 'VendorContracting',
	VendorOnboarding: 'VendorOnboarding',
	AccessForm: 'AccessForm',
	VendorRiskAssessment: 'VendorRiskAssessment',
	VendorScoping: 'VendorScoping',
	VendorDetailedAssessment: 'VendorDetailedAssessment',
	VendorRiskMonitoring: 'VendorRiskMonitoring',
	VendorTermination: 'VendorTermination',
	QuestionnaireBuilder: 'QuestionnaireBuilder',
	VendorRiskProfiling: 'VendorRiskProfiling',

	//Vendor
	ManageVendor: 'ManageVendor',
	VendorAddition: 'VendorAddition',
	VendorDetails: 'VendorDetails',
	VendorView: 'VendorView',
	OverView: 'OverView',
	DRM: 'DRM',

	//Administration
	AdministrationOverView: 'AdministrationOverView',
	ManagerView: 'ManagerView',
	ManagerEdit: 'ManagerEdit',
	CreateCriticalityMatrix: 'CreateCriticalityMatrix',

	//MasterFeed
	MasterFeed: 'MasterFeed',

	//Master Questionnaire
	MasterQuestionnaire: 'MasterQuestionnaire',

	//CheckList Questionnaire
	ChecklistScreen: 'ChecklistScreen',

	//Billing
	Billing: 'Billing'

	// ---------------------- VRM PERMISSIONS ENDS -----------------------------
}

export const dependentPermissionsForSidebarGrouping = {}

export const dependentPermissionsForRoutes = {
	DashboardScreen: [
		permissionKeys.ProcessCriticality,
		permissionKeys.ProcessCriticalityQuest,
		permissionKeys.ServiceDescription,
		permissionKeys.VendorSelection,
		permissionKeys.VendorContracting,
		permissionKeys.VendorOnboarding,
		permissionKeys.AccessForm,
		permissionKeys.VendorRiskAssessment,
		permissionKeys.VendorScoping,
		permissionKeys.VendorDetailedAssessment,
		permissionKeys.VendorRiskMonitoring,
		permissionKeys.VendorRiskProfiling,
		permissionKeys.VendorTermination,
		permissionKeys.VendorRiskMonitoring,
		permissionKeys.QuestionnaireBuilder,
		permissionKeys.VendorRiskProfiling,
		permissionKeys.ProcessScreen
	],
	IntegrationScreen: [
		permissionKeys.VendorAddition,
		permissionKeys.VendorDetails,
		permissionKeys.VendorView,
		permissionKeys.OverView,
		permissionKeys.RiskManagerQuest
	]
}
// export const dependentPermissionsForRoutes = {}
