import Text from '@commons/Text'
import adminRoutesConstant from '@constants/adminRoutesConstant'
import { Box } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

const SubscriptionExpiredScreen = () => {
	const user = useSelector((state) => state.auth.user.info)
	const location = useLocation()

	const { current: billingUrl } = React.useRef(adminRoutesConstant.adminPaths.BillingScreen.url())

	const isPlanExpired = React.useMemo(() => {
		return !user.plan && user.email && !(location.pathname === billingUrl)
	}, [location, JSON.stringify(user)])

	React.useEffect(() => {
		const page = document.getElementById('page')

		if (page) {
			if (isPlanExpired) {
				page.classList.add('blur-children')
			} else {
				page.classList.remove('blur-children')
			}
		}
	}, [isPlanExpired])

	return (
		<Box
			position='absolute'
			top='50%'
			left='50%'
			display={isPlanExpired ? 'block' : 'none'}
			width='100%'
			height='100%'
			sx={{
				filter: 'none !important',
				'& *': {
					filter: 'none !important'
				},

				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				zIndex: 99999,
				background: 'rgba(255, 255, 255, 0.4)'
			}}
		>
			<Text
				// className={clsx(classes.text, classes.center)}
				fontSize={32}
				align='center'
				sx={{
					width: '100%',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					zIndex: 99999,
					background: 'rgba(255, 255, 255, 0.4)'
				}}
			>
				<span>You need to{'  '}</span>
				<Text fontSize={'inherit'} ascent={'base'} component={Link} textDecoration='underline' to={billingUrl}>
					subscribe
				</Text>
				<span>{'  '}to unlock this page!</span>
			</Text>
		</Box>
	)
}

export default SubscriptionExpiredScreen
