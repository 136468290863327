import { createAsyncThunk } from '@reduxjs/toolkit'

export const getPolicyLists = createAsyncThunk('policy/getPolicyLists', async (_, thunkApi) => {
	const { rejectWithValue } = thunkApi
	try {
		// const response = await getAllPolicies().api()

		const response = {
			detail: 'SUCCESS',
			code: 200,
			results: {
				data: [
					{
						id: 5,
						name: 'Risk Manager',
						slug: 'rm1',
						RouteName: null,
						group: 'Risk Manager'
					},
					{
						id: 6,
						name: 'Vendor Addition',
						slug: 'v1',
						RouteName: 'VendorAddition',
						group: 'Vendor'
					},
					{
						id: 7,
						name: 'Vendor Details',
						slug: 'v2',
						RouteName: 'VendorDetails',
						group: 'Vendor'
					},
					{
						id: 8,
						name: 'Vendor View',
						slug: 'v3',
						RouteName: 'VendorView',
						group: 'Vendor'
					},
					{
						id: 9,
						name: 'Overview',
						slug: 'v4',
						RouteName: 'OverView',
						group: 'Vendor'
					},
					{
						id: 1,
						name: 'Administration',
						description: null,
						slug: 'admin1',
						api_endpoint: null,
						RouteName: 'AdministrationOverView',
						canRead: true,
						canCreate: true,
						canUpdate: true,
						canDelete: true,
						group: 'Administration'
					},
					{
						id: 1,
						name: 'MasterQuestionnaire',
						description: null,
						slug: 'admin1',
						api_endpoint: null,
						RouteName: 'MasterQuestionnaire',
						canRead: true,
						canCreate: true,
						canUpdate: true,
						canDelete: true,
						group: 'MasterQuestionnaire'
					},
					{
						id: 15,
						name: 'ChecklistScreen',
						description: null,
						slug: 'admin1',
						api_endpoint: null,
						RouteName: 'ChecklistScreen',
						canRead: true,
						canCreate: true,
						canUpdate: true,
						canDelete: true,
						group: 'ChecklistScreen'
					},
					{
						id: 11,
						name: 'Process VRM',
						description: null,
						slug: 'vrm1',
						api_endpoint: null,
						RouteName: 'ProcessVRM',
						canRead: true,
						canCreate: true,
						canUpdate: true,
						canDelete: true,
						group: 'VRM'
					},
					{
						id: 1,
						name: 'CRUD Master',
						description: null,
						slug: 'master2',
						api_endpoint: null,
						RouteName: 'MasterFeed',
						canRead: true,
						canCreate: true,
						canUpdate: true,
						canDelete: true,
						group: 'Master'
					},
					{
						id: 12,
						name: 'Manage Vendor',
						description: null,
						slug: 'v5',
						api_endpoint: null,
						RouteName: 'ManageVendor',
						canRead: true,
						canCreate: true,
						canUpdate: true,
						canDelete: true,
						group: 'Vendor'
					},
					{
						id: 10,
						name: 'Dashboard',
						description: null,
						slug: 'admin1',
						api_endpoint: null,
						RouteName: 'DashboardScreen',
						canRead: true,
						canCreate: true,
						canUpdate: true,
						canDelete: true,
						group: 'Dashboard'
					},
					{
						id: 2,
						name: 'Add Risk Manager',
						slug: 'admin2',
						RouteName: null,
						group: 'Risk Manager'
					},
					{
						id: 3,
						name: 'Edit Risk Manager',
						slug: 'admin3',
						RouteName: null,
						group: 'Risk Manager'
					},
					{
						id: 4,
						name: 'Delete Risk Manager',
						slug: 'admin4',
						RouteName: null,
						group: 'Risk Manager'
					},
					{
						id: 16,
						name: 'Create Criticality Matrix',
						slug: 'crit-matrix',
						RouteName: 'CreateCriticalityMatrix',
						group: 'Administration'
					},
					{
						id: 17,
						name: 'Billing',
						slug: 'billing',
						RouteName: 'Billing',
						group: 'Billing'
					}
				]
			}
		}
		if (response) {
			if (!response.error) {
				console.log('Response in policy reducer', response)
				// const allIds = response.map((item) => item.id);
				// const byId = response.reduce((accu, item) => {
				//     const val = item;
				//     const valId = item.id;

				//     return { ...accu, [valId]: val };
				// }, {});
				// return { allIds: allIds, byId: byId ,allRouteNamesByPolicy:[]};
				return response.results.data
			} else {
				rejectWithValue({
					response
				})
			}
		} else {
			rejectWithValue({
				response: {
					data: {
						// Because same structure of error comes from backend. Keeps code error free.
						detail: 'We are facing an issue right now. Please try again later.'
					}
				}
			})
		}
	} catch (error) {
		return rejectWithValue(error)
	}
})
