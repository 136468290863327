import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
// import SettingsIcon from "@mui/icons-material/Settings";
import adminRoutesConstant from '@constants/adminRoutesConstant'
import { permissionKeys } from '@constants/permissions'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import { logoutUser } from '@redux/auth/slice'
import { EditVendor } from '@services/VRMService'
import { messaging } from '@utils/fcm'
import { deleteToken, isSupported } from 'firebase/messaging'
import mixpanel from 'mixpanel-browser'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import CardWrapperByRatio from '../Card/CardWrapperByRatio'
import AppModal from '../Modal/AppModal'

const MoreVerticalMenu = ({ openSidebar, isOtherdata, vendorId, vendorData, templateID, assessDate }) => {
	const dispatch = useDispatch()
	const [openMoreMenu, setOpenMoreMenu] = React.useState(null)
	const navigate = useNavigate()
	const location = useLocation()
	const [openRejectVendor, setOpenRejectVendor] = React.useState(false)
	const toggleRejectVendor = () => setOpenRejectVendor(!openRejectVendor)
	const handleCloseRejectVendorModal = () => setOpenRejectVendor(false)

	const [openOnboardVendor, setOpenOnboardVendor] = React.useState(false)
	const toggleOnboardVendor = () => setOpenOnboardVendor(!openOnboardVendor)
	const handleCloseOnboardVendorModal = () => setOpenOnboardVendor(false)

	const policies = useSelector((state) => state.auth.user.info?.role?.policy_list)
	const policiesIndexedByRouteName = policies?.reduce((acc, policy) => {
		acc[policy.RouteName] = policy
		return acc
	}, {})

	// console.log('vendorId', vendorId)
	// console.log('vendorData', vendorData)
	// console.log('templateID', templateID)
	// console.log('assessDate', policiesIndexedByRouteName)

	const handleMenuClose =
		(url, newTab = false) =>
		() => {
			if (url) {
				if (url === 'logout') {
					isSupported()
						.then((supported) => {
							if (supported) deleteToken(messaging)
						})
						.catch((e) => {
							console.warn('FCM not supported: ' + e)
						})

					mixpanel.reset()
					dispatch(logoutUser())
					navigate(adminRoutesConstant.adminPaths.LoginScreen.url())
					return
				}

				if (newTab) {
					window.open(url, '_blank').focus()
				} else {
					navigate(url)
				}
			}

			setOpenMoreMenu(null)
		}
	const handleMenuOpen = (e) => setOpenMoreMenu(e.target)

	const handleRejectingVendor = () => {
		const updatedRejectData = { ...vendorData, assessment_end_date: assessDate, template_id: templateID }
		updatedRejectData.is_active = 4
		if (updatedRejectData.vendor_reassessment_date === 'NA') {
			updatedRejectData.vendor_reassessment_date = null
		}
		if (updatedRejectData.vendor_registration_date === 'NA') {
			updatedRejectData.vendor_registration_date = null
		}
		console.log('updatedRejectData', updatedRejectData)

		const rejectedAPI = EditVendor(vendorId)
		rejectedAPI
			.api({
				data: updatedRejectData
			})
			.then((response) => {
				if (response) {
					if (!response.error) {
						console.log('Vendor Rejected Successfully', response)
						enqueueSnackbar('Vendor Rejected Successfully!!! ', { variant: 'success' })
						handleCloseRejectVendorModal()
					} else {
						enqueueSnackbar(`Error in Rejecting Vendor !!! - ${response.data.message} `, { variant: 'error' })
						handleCloseRejectVendorModal()
					}
				}
			})
	}

	const handleOnboardingVendor = () => {
		const updatedOnBoardedData = { ...vendorData, assessment_end_date: assessDate, template_id: templateID }
		updatedOnBoardedData.is_active = 2
		console.log('updatedOnBoardedData', updatedOnBoardedData)

		const rejectedAPI = EditVendor(vendorId)
		rejectedAPI
			.api({
				data: updatedOnBoardedData
			})
			.then((response) => {
				if (response) {
					if (!response.error) {
						console.log('Vendor Onboarded Successfully', response)
						enqueueSnackbar('Vendor Onboarded Successfully!!! ', { variant: 'success' })
						handleCloseOnboardVendorModal()
					} else {
						enqueueSnackbar(`Error in Onboarding Vendor!!! - ${response.data.message} `, { variant: 'error' })
						handleCloseOnboardVendorModal()
					}
				}
			})
	}

	// useEffect(() => {
	// 	fetchVendorDetailsByID()
	// 	fetchAssessmentData()
	// }, [vendorId])

	return (
		<>
			<MoreVertIcon
				onClick={handleMenuOpen}
				sx={{
					color: (theme) => theme.palette.ascents.secondary,
					cursor: 'pointer',
					'&:hover, &:focus': {
						color: (theme) => theme.palette.ascents.wash
					},

					fontSize: isOtherdata ? '2.5rem' : '1rem',
					transform: openSidebar || isOtherdata ? 'rotate(0deg)' : 'rotate(90deg)',
					marginTop: !openSidebar ? 5 : 0,
					marginLeft: isOtherdata ? '1.5rem' : 0,
					verticalAlign: 'bottom',
					alignItems: 'center'
				}}
			/>
			<Menu
				id='moreVerticalMenu'
				anchorEl={openMoreMenu}
				keepMounted
				open={Boolean(openMoreMenu)}
				onClose={handleMenuClose()}
				sx={{
					'& .MuiMenu-paper': {
						backgroundColor: (theme) => theme.palette.ascents.washLight
					},
					'& .MuiListItemIcon-root': {
						minWidth: 36
					},
					'& .Mui-selected': {
						'& .MuiListItemIcon-root, & .MuiListItemText-root': {
							color: (theme) => theme.palette.ascents.base
						}
					}
				}}
				disableAutoFocusItem
			>
				{/* {policies.includes('MyAccountScreen') && (
					<MenuItem
						selected={location.pathname === adminRoutesConstant.adminPaths.MyAccountScreen.url()}
						onClick={handleMenuClose(adminRoutesConstant.adminPaths.MyAccountScreen.url())}
					>
						<ListItemIcon>
							<AccountCircleIcon />
						</ListItemIcon>
						<ListItemText>My Account</ListItemText>
					</MenuItem>
				)} */}
				{/* {policies.includes('ManageVendor') && (
					<MenuItem
						selected={location.pathname === adminRoutesConstant.adminPaths.BillingScreen.url()}
						onClick={handleMenuClose(adminRoutesConstant.adminPaths.BillingScreen.url())}
					>
						<ListItemIcon>
							<CreditCardIcon />
						</ListItemIcon>
						<ListItemText>Billing</ListItemText>
					</MenuItem>
				)} */}
				{/*{policies.includes('InternalTicketScreen') && (
					<MenuItem
						selected={location.pathname === adminRoutesConstant.adminPaths.InternalTicketScreen.url()}
						onClick={handleMenuClose(adminRoutesConstant.adminPaths.InternalTicketScreen.url())}
					>
						<ListItemIcon>
							<LocalPlayIcon />
						</ListItemIcon>
						<ListItemText>My Tickets</ListItemText>
					</MenuItem>
				)}
				{policies.includes('CreatePdfReportScreen') && (
					<MenuItem
						selected={location.pathname === adminRoutesConstant.adminPaths.CreatePdfReportScreen.url()}
						onClick={handleMenuClose(adminRoutesConstant.adminPaths.CreatePdfReportScreen.url())}
					>
						<ListItemIcon>
							<PlayForWorkIcon />
						</ListItemIcon>
						<ListItemText>Reports</ListItemText>
					</MenuItem>
				)}
				{policies.includes('FeedbackScreen') && (
					<MenuItem
						selected={location.pathname === adminRoutesConstant.adminPaths.FeedbackScreen.url()}
						onClick={handleMenuClose(adminRoutesConstant.adminPaths.FeedbackScreen.url())}
					>
						<ListItemIcon>
							<FeedbackIcon />
						</ListItemIcon>
						<ListItemText>Feedback</ListItemText>
					</MenuItem>
				)} */}
				{isOtherdata ? (
					<>
						{/* <MenuItem onClick={handleMenuClose('terminate')}>
							<ListItemText>Terminate Vendor</ListItemText>
						</MenuItem> */}
						<MenuItem onClick={toggleRejectVendor}>
							{/* <ListItemIcon>
								<MeetingRoomIcon />
							</ListItemIcon> */}
							<ListItemText>Reject Vendor</ListItemText>
						</MenuItem>

						<MenuItem onClick={toggleOnboardVendor}>
							{/* <ListItemIcon>
								<MeetingRoomIcon />
							</ListItemIcon> */}
							<ListItemText>Onboard Vendor</ListItemText>
						</MenuItem>
					</>
				) : (
					<>
						<MenuItem onClick={handleMenuClose('logout')}>
							<ListItemIcon>
								<MeetingRoomIcon />
							</ListItemIcon>
							<ListItemText>Logout</ListItemText>
						</MenuItem>
						<MenuItem
							selected={location.pathname === adminRoutesConstant.adminPaths.MyAccountScreen.url()}
							onClick={handleMenuClose(adminRoutesConstant.adminPaths.MyAccountScreen.url())}
						>
							<ListItemIcon>
								<AccountCircleIcon />
							</ListItemIcon>
							<ListItemText>My Account</ListItemText>
						</MenuItem>
						{policiesIndexedByRouteName && policiesIndexedByRouteName[permissionKeys.Billing] && (
							<MenuItem
								selected={location.pathname === adminRoutesConstant.adminPaths.BillingScreen.url()}
								onClick={handleMenuClose(adminRoutesConstant.adminPaths.BillingScreen.url())}
							>
								<ListItemIcon>
									<CreditCardIcon />
								</ListItemIcon>
								<ListItemText>Billing</ListItemText>
							</MenuItem>
						)}
					</>
				)}
			</Menu>

			{/* This is for Rejecting Vendor */}
			<AppModal
				sx={{ zIndex: '9999 ' }}
				onClose={handleCloseRejectVendorModal}
				maxWidth='sm'
				fullWidth
				hideClose
				open={openRejectVendor}
			>
				<CardWrapperByRatio
					boxProps={{
						padding: '3rem !important'
					}}
					hideShadow
					// aspectRatio={2 / 5}
					aspectRatio={2 / 5}
				>
					<Box sx={{ fontWeight: 'strong', marginLeft: '1.7rem', fontSize: '1.5rem' }}>Do you wish to Reject Vendor ??</Box>
					<Box sx={{ marginTop: '2.5rem', marginLeft: '1.8rem', fontSize: '2rem' }}>
						<Button color='error' onClick={handleRejectingVendor} variant='outlined' size='large'>
							Reject Vendor
						</Button>
						<Button sx={{ marginLeft: '6rem' }} onClick={handleCloseRejectVendorModal} variant='outlined' size='large'>
							Cancel
						</Button>
					</Box>
				</CardWrapperByRatio>
			</AppModal>
			{/* This is for Onboarding Vendor */}
			<AppModal
				sx={{ zIndex: '9999 ' }}
				onClose={handleCloseOnboardVendorModal}
				maxWidth='sm'
				fullWidth
				hideClose
				open={openOnboardVendor}
			>
				<CardWrapperByRatio
					boxProps={{
						padding: '3rem !important'
					}}
					hideShadow
					// aspectRatio={2 / 5}
					aspectRatio={2 / 5}
				>
					<Box sx={{ fontWeight: 'strong', fontSize: '1.5rem' }}>Do you wish to onboard the vendor ??</Box>
					<Box sx={{ marginTop: '2.5rem', marginLeft: '1.7rem', fontSize: '2rem' }}>
						<Button color='error' onClick={handleOnboardingVendor} variant='outlined' size='large'>
							OnBoard Vendor
						</Button>
						<Button sx={{ marginLeft: '6rem' }} onClick={handleCloseOnboardVendorModal} variant='outlined' size='large'>
							Cancel
						</Button>
					</Box>
				</CardWrapperByRatio>
			</AppModal>
		</>
	)
}

export default MoreVerticalMenu
