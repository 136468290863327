import { AxiosService } from '@interceptors/axiosInstance'

export const configureMfa = (config) => {
	return AxiosService.post('/rest/v1/mfa/configure/', config)
}

// export const configureNewMfa = (config) => {
// 	return AxiosService.post('/api/v1/auth/otp-mfa/generate/', config)
// }

export const newEnableMfa = (token, config) => {
	return AxiosService.post(`/api/v1/auth/otp-mfa/verify/${token}`, config)
}

export const enableMfa = (config) => {
	return AxiosService.post('/rest/v1/mfa/enable/', config)
}

export const getMfaRecoveryCodes = (config) => {
	return AxiosService.get('/rest/v1/mfa/recovery_code/', config)
}

// export const disableMfa = (config) => {
// 	return AxiosService.post('/rest/v1/mfa/disable/', config)
// }

export const disableMfa = (config) => {
	return AxiosService.post('/api/v1/auth/otp-mfa/disable', config)
}

export const checkMfaStatus = (config) => {
	return AxiosService.get('/rest/v1/mfa/enable/', config)
}

export const verifyMfa = (config) => {
	return AxiosService.post('/rest/v1/mfa/verify/', {
		...config,
		checkToken: false
	})
}

export const newVerifyMfa = (config) => {
	return AxiosService.post('/api/v1/auth/otp-mfa/validate', {
		...config,
		checkToken: false
	})
}
