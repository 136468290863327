import { createTheme } from '@mui/material/styles'

/* 
    @objects
    > overrides: Changes default style injected by Material-UI into the DOM

    > ascents: Specially for dynamic input , buttons for optimization.

    > palette: Color scheme in whole app. Reference from EO2V2_Specifications

    > typography: Styles for all texts in h1,button etc.
*/
const KEYS = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']
export const BREAKPOINTS = {
	xxs: 0,
	xs: 480,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1280,
	xll: 1300,
	xxl: 1440,
	xxxl: 1600,
	xxxxl: 1700,
	xxxxxl: 1920
}
export const ASCENTS = {
	primary: '#433692',
	chipBG: 'rgba(67,54,146,0.03)',
	lightBlue: '#4bb8ec',
	bluishGrey: '#09061b',
	textDark: '#5F5D70',
	textGrey: '#4A4A4A',
	dark: '#101418',

	// new colors
	base: '#19133F',
	secondary: '#766f9c',
	baseLow: '#28214b',
	baseDisabled: 'rgba(25,19,63, 0.15)',
	wash: '#ebe9f4',
	washDark: '#a8a3c3',
	washLight: '#f9f8fd',
	greyBlue: '#bbc1e0',
	shadow: '#e8e8e8',
	disabled: '#ffffff0f',

	// new
	green: '#34A853',
	mediumDarkGreen: '#188736',
	red: '#ff103a',
	lightRed: '#ff5564',
	amber: '#ff8010',
	lightAmber: '#ffa250',
	yellow: '#fbb000',
	lightYellow: '#ffda6a',
	black: '#000000',
	grey: '#827f91',
	white: '#FFFFFF',
	trans: 'transparent',
	lightWhite: '#f1f1f1',
	faintBlue: '#3d8ec4',
	orange: '#FFA500'
}

const breakpoints = {
	keys: KEYS,
	values: BREAKPOINTS
}

const theme = createTheme({
	breakpoints: breakpoints,

	components: {
		MuiInputLabel: {
			root: {
				fontSize: 12
			}
		},
		MUIDataTableBodyCell: {
			root: {
				boxShadow: 'none'
			},
			title: {
				padding: 0
			}
		},
		MUIDataTable: {
			responsiveScroll: {
				maxHeight: 'unset',
				overflowX: 'unset',
				overflowY: 'unset'
			}
		},
		// MuiToolbar: {
		//     root: {
		//         top: 0,
		//         position: 'sticky',
		//         background: 'white',
		//         zIndex: '100',
		//         backgroundColor: 'yellow',
		//         minHeight: "0 !important",
		//     },
		// },
		MUIDataTableHeadCell: {
			data: {
				textTransform: 'capitalize'
			}
		},
		MUIDataTableToolbarSelect: {
			styleOverrides: {
				root: {
					backgroundColor: 'transparent !important'
				}
			}
		},
		MuiInput: {
			root: {
				fontSize: 14
			}
		},
		MuiFormControl: {
			root: {
				marginTop: 14
			}
		}
	},

	palette: {
		mode: 'light',
		primary: {
			light: '#433692',
			main: '#433692',
			dark: '#433692',
			wash: '#EBECFF',
			contrastText: '#fff'
		},
		secondary: {
			light: '#19133F',
			main: '#19133F',
			dark: '#19133F',
			contrastText: '#000'
		},
		success: {
			light: '#9df971',
			main: '#69C540',
			dark: '#329402'
		},
		text: {
			primary: '#4A4A4A',
			secondary: '#19133F',
			disabled: '#ddd',
			systemWhite: '#fff',
			systemWash: '#ECECEC',
			hint: 'rgba(0, 0, 0, 0.38)'
		},
		background: {
			default: '#FFFFFF'
		},

		ascents: ASCENTS
	},
	typography: {
		fontFamily: '"Roboto", sans-serif',

		h1: {
			fontFamily: '"Open Sans", sans-serif !important',
			fontSize: 36,
			fontWeight: 400
		},
		h2: {
			fontFamily: '"Open Sans", sans-serif !important',
			fontSize: 25
		},
		h3: {
			fontFamily: '"Open Sans", sans-serif !important',
			fontSize: 20
		},
		h4: {
			fontFamily: '"Open Sans", sans-serif !important',
			fontSize: 18
		},
		h5: {
			fontFamily: '"Open Sans", sans-serif !important',
			fontSize: 18
		},
		h6: {
			fontFamily: '"Open Sans", sans-serif !important',
			fontSize: 18,
			fontWeight: 400
		},
		body1: {
			fontFamily: '"Roboto", sans-serif',
			fontSize: 14
		},
		body2: {
			fontFamily: '"Roboto", sans-serif',
			fontSize: 12,
			fontWeight: '300'
		},
		button: {
			fontFamily: '"Open Sans", sans-serif !important',
			textTransform: 'capitalize',
			fontSize: 14
		},
		thead: {
			fontFamily: '"Open Sans", sans-serif !important'
		}
	}
})

export default theme
