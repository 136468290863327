import Navbar from '@commons/Navbar/Navbar'
import adminRoutesConstant from '@constants/adminRoutesConstant'
import { Box } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'

const BaseScreenLayout = ({
	children,
	hideNavbar = false,
	tabs = [],
	childrenWrapperProps,
	handleToggleSidebar,
	handleTimeFrameChange,
	selectedTimeFrame,
	filtersByTime,
	sidebarIsOpen
}) => {
	const currentLocation = useLocation()

	const allRouteNamesByPolicy = useSelector((state) => state.auth.allRouteNamesByPolicy)

	const pathName = currentLocation.pathname

	const isDashboardPage = React.useMemo(() => {
		return adminRoutesConstant.routesByUrls[pathName]?.routeName === 'DashboardScreen'
	}, [pathName])

	//  E.g - /securityOverview in /securityOverview/securityEvents
	const currentRootRoute = React.useMemo(() => {
		return adminRoutesConstant.routesByUrls['/'.concat(pathName.split('/')[1])] || {}
	}, ['/'.concat(pathName.split('/')[1])])

	const currentParentRoute = React.useMemo(() => {
		const splitPath = pathName.split('/')

		//  E.g - /compliance/gdpr in /compliance/gdpr/dashboard
		if (splitPath.length > 3) {
			return adminRoutesConstant.routesByUrls[splitPath.slice(0, splitPath.length - 1).join('/')] || {}
		} else {
			let match = {}
			Object.keys(adminRoutesConstant.routesByUrls).map((key) => {
				const matchFound = matchPath({ path: key }, location.pathname)
				if (matchFound) {
					match = adminRoutesConstant.routesByUrls[key]
				}
			})

			return match
		}
	}, [pathName])

	// eslint-disable-next-line no-unused-vars
	const currentRootRouteTabs = React.useMemo(() => {
		return tabs.length > 0
			? tabs
			: (adminRoutesConstant.nestedRoutes[currentRootRoute.routeName] || []).filter(
					(each) => allRouteNamesByPolicy.indexOf(each.routeName) > -1
			  )
	}, [currentRootRoute, tabs, JSON.stringify(allRouteNamesByPolicy)])

	return (
		<>
			{!hideNavbar && (
				<Navbar
					title={currentParentRoute.label}
					handleToggleSidebar={handleToggleSidebar}
					selectedTimeFrame={selectedTimeFrame}
					handleTimeFrameChange={handleTimeFrameChange}
					filtersByTime={filtersByTime}
					sidebarIsOpen={sidebarIsOpen}
				/>
			)}
			<Box
				sx={{
					padding: isDashboardPage ? '0' : '0.5rem',
					paddingTop: '0.25rem',
					height: isDashboardPage ? 'calc(100vh - 19%)' : 'auto'
				}}
			>
				<Box
					sx={{
						height: '100%'
					}}
					{...childrenWrapperProps}
				>
					{children}
				</Box>
			</Box>
		</>
	)
}

export default BaseScreenLayout
