import Icon from '@commons/Icon'
import { SvgIcons } from '@utils/icons'

function SidebarListKeyConstantGenerator(key, label, icon, routeName) {
	this.key = key
	this.label = label
	this.isShownOnSidebar = true
	this.Icon = icon
	this.routeName = routeName || key
}

export const SidebarListKeyConstants = {
	DashboardScreen: new SidebarListKeyConstantGenerator('DashboardScreen', 'Dashboard', (props) => (
		<Icon {...props}>
			<SvgIcons.DASHBOARD_SVG className={props?.className} />
		</Icon>
	)),
	ProcessVRM: new SidebarListKeyConstantGenerator('ProcessVRM', 'Assessment Kit', (props) => (
		<Icon {...props}>
			<SvgIcons.MandateSvg className={props?.className} />
		</Icon>
	)),
	ManageVendor: new SidebarListKeyConstantGenerator('ManageVendor', `Vendor's Profile`, (props) => (
		<Icon {...props}>
			<SvgIcons.CogSvg className={props?.className} />
		</Icon>
	)),
	AdministrationOverView: new SidebarListKeyConstantGenerator('AdministrationOverView', 'Administration', (props) => (
		<Icon {...props}>
			<SvgIcons.ToolsSvg className={props?.className} />
		</Icon>
	)),
	//Thsi is the comment 
	MasterFeed: new SidebarListKeyConstantGenerator('MasterFeed', 'Master Data Ingestion', (props) => (
		<Icon {...props}>
			<SvgIcons.TableGroupSVG className={props?.className} />
		</Icon>
	)),
	// MasterQuestionnaire: new SidebarListKeyConstantGenerator('MasterQuestionnaire', 'Questionnaire Bucket', (props) => (
	// 	<Icon {...props}>
	// 		<SvgIcons.BriefcaseSvg className={props?.className} />
	// 	</Icon>
	// )),
	ChecklistScreen: new SidebarListKeyConstantGenerator('ChecklistScreen', 'ZIN Checklist Generator', (props) => (
		<Icon {...props}>
			<SvgIcons.COMPLIANCE_SVG className={props?.className} />
		</Icon>
	))
}

const SidebarOrderedListConstant = [
	'DashboardScreen',
	'ProcessVRM',
	'ManageVendor',
	'MasterFeed',
	// 'MasterQuestionnaire',
	'ChecklistScreen',
	'AdministrationOverView'
]

export default SidebarOrderedListConstant
