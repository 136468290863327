import { ReactComponent as DesktopDownloadSvg } from '@assets/icons/DesktopDownload.svg'
import { ReactComponent as ADMINISTRATION_SVG } from '@assets/icons/administration.svg'
import APPLE_LIGHT_SVG from '@assets/icons/apple_light.svg'
import ARROW_SVG from '@assets/icons/arrow.svg'
import BADGE_PNG from '@assets/icons/badge.png'
import BankIconSvg, { ReactComponent as BankSvg } from '@assets/icons/bank.svg'
import { ReactComponent as CancelSVG } from '@assets/icons/cancel.svg'
import { ReactComponent as CheckSVG } from '@assets/icons/check.svg'
import { ReactComponent as CHECK_CIRCLE_SVG } from '@assets/icons/check_circle.svg'
import { ReactComponent as ClockSVG } from '@assets/icons/clock.svg'
import { ReactComponent as COMPLIANCE_SVG } from '@assets/icons/compliance.svg'
import { ReactComponent as DEV_BUG_SVG } from '@assets/icons/dev bug.svg'
import DOCUMENT_ERROR_ICON from '@assets/icons/document_error_icon.png'
import { ReactComponent as DollarSvg } from '@assets/icons/dollar.svg'
import { ReactComponent as EmployeeSvg } from '@assets/icons/employees.svg'
import ERROR_SVG from '@assets/icons/error_graph.svg'
import FEATURE_REQ_SVG from '@assets/icons/feature req.svg'
import { ReactComponent as FilterSVG } from '@assets/icons/filter.svg'
import FIREWALL_SVG, { ReactComponent as FireSvg } from '@assets/icons/fire.svg'
import GREEN_CUBE_SVG from '@assets/icons/green_cube.svg'
import { ReactComponent as HACKER_SVG } from '@assets/icons/hacker.svg'
import HACKER2_SVG from '@assets/icons/hacker2.svg'
import { ReactComponent as HELP_SVG } from '@assets/icons/help.svg'
import { ReactComponent as HospitalSvg } from '@assets/icons/hospital.svg'
import HOURGLASS_SVG from '@assets/icons/hourglass.svg'
import INFO_SVG from '@assets/icons/info.svg'
import INIT_SVG from '@assets/icons/init.svg'
import INSTALL_SVG from '@assets/icons/install.svg'
import INTERVAL_SVG from '@assets/icons/interval.svg'
import LINUX_SVG from '@assets/icons/linux.svg'
import LINUX_LIGHT_SVG from '@assets/icons/linux_light.svg'
import { ReactComponent as LocationSvg } from '@assets/icons/location.svg'
import { ReactComponent as MANAGEMENT_SVG } from '@assets/icons/management.svg'
import MapMarkerAmberSvg from '@assets/icons/map_amber_dot.svg'
import MapMarkerGreenSvg from '@assets/icons/map_green_dot.svg'
import MapMarkerRedSvg from '@assets/icons/map_red_dot.svg'
import { ReactComponent as MoneySvg } from '@assets/icons/money.svg'
import WEEKLY_SVG from '@assets/icons/monthly.svg'
import OTHER_SVG from '@assets/icons/other.svg'
import { ReactComponent as OUTLINED_CIRCLE_SVG } from '@assets/icons/outlined_circle.svg'
import PC_SVG from '@assets/icons/pc.svg'
import { ReactComponent as SECURITYOVERVIEW_SVG } from '@assets/icons/shield.svg'
import { ReactComponent as ToolsSvg } from '@assets/icons/tools.svg'
import RISK_MATRIX from '@assets/icons/performance.png'

//Vendor
import { ReactComponent as VendorVECTOR } from '@assets/icons/Vector.svg'
import { ReactComponent as VendorICON } from '@assets/icons/logo.svg'
import { ReactComponent as VendorSUCCESS } from '@assets/icons/vendor-success.svg'

import { ReactComponent as DOWNLOAD_SVG } from '@assets/icons/download.svg'
import { ReactComponent as HIDE_COL_SVG } from '@assets/icons/hide_col.svg'
import { ReactComponent as HOST_PC_SVG } from '@assets/icons/pc.svg'

import { ReactComponent as SetupSvg } from '@assets/icons/Setup.svg'
import UX_BUG_SVG from '@assets/icons/UX bug.svg'
import ActiveIntegration_SVG from '@assets/icons/access_account.svg'
import Applicable_SVG from '@assets/icons/applicable.svg'
import Aws_SVG from '@assets/icons/aws.svg'
import Azure_SVG from '@assets/icons/azure.svg'
import { ReactComponent as BriefcaseSvg } from '@assets/icons/briefcase.svg'
import { ReactComponent as CogSvg } from '@assets/icons/cog.svg'
import { ReactComponent as DASHBOARD_SVG } from '@assets/icons/dashboard.svg'
import Gcp_SVG from '@assets/icons/gcp.svg'
import { ReactComponent as CLOUD_SVG } from '@assets/icons/ic_outline-cloud.svg'
import { ReactComponent as MandateSvg } from '@assets/icons/list-check.svg'
import Not_Applicable_SVG from '@assets/icons/not_applicable.svg'
import SoftwareIntegration_SVG from '@assets/icons/software_integration.svg'
import { ReactComponent as SPARK_SVG } from '@assets/icons/spark.svg'
import { ReactComponent as SPARK2_SVG } from '@assets/icons/spark2.svg'
import { ReactComponent as SpiderSVG } from '@assets/icons/spider.svg'
import { ReactComponent as StarSvg } from '@assets/icons/star.svg'
import SYNC_PNG from '@assets/icons/sync.png'
import { ReactComponent as TableGroupSVG } from '@assets/icons/table_group.svg'
import { ReactComponent as TableMenuSVG } from '@assets/icons/table_menu.svg'
import { ReactComponent as Export_SVG } from '@assets/icons/ui_export.svg'
import USER_SVG from '@assets/icons/user.svg'
import { ReactComponent as VendorsSvg } from '@assets/icons/vendors.svg'
import { ReactComponent as WarningSVG } from '@assets/icons/warning.svg'
import MONTHLY_SVG from '@assets/icons/week.svg'
import WHITE_CUBE_SVG from '@assets/icons/white_cube.svg'
import WINDOWS_SVG from '@assets/icons/windows.svg'
import WINDOWS_LIGHT_SVG from '@assets/icons/windows_light.svg'
import { ReactComponent as LineSvg } from '@assets/images/Line.svg'
import { ReactComponent as OsLogoGroupSvg } from '@assets/images/OsLogoGroup.svg'
// import ACCESSKEY_SVG from '@assets/images/access_key.svg'
import ACCESSKEY_IMG from '@assets/images/cloud_monitor/access_key.png'

import { ReactComponent as AndroidSvg } from '@assets/images/android.svg'
import APPLE_SVG, { ReactComponent as AppleLogoSvg } from '@assets/images/apple.svg'
import AWS_ADD_CREDENTIAL_SVG from '@assets/images/aws_add_credential.svg'
import { ReactComponent as ApiSvg } from '@assets/images/carbon_api.svg'
import { ReactComponent as DBSvg } from '@assets/images/carbon_data-base.svg'
import { ReactComponent as DBCheckSvg } from '@assets/images/carbon_data-check.svg'
import { ReactComponent as DBDefinitionSvg } from '@assets/images/carbon_data-definition.svg'
import CloudConfig from '@assets/images/cloud-config.svg'
import ADD_AZURE_CREDENTIALS_IMG from '@assets/images/cloud_monitor/azure_add_credentials.png'
import GENERATE_CLIENT_SECRET_IMG from '@assets/images/cloud_monitor/generate_client_secret.png'
import GENERATE_KEY_IMG from '@assets/images/cloud_monitor/generate_key.png'
import GRANT_ACCESS_NAME_IMG from '@assets/images/cloud_monitor/grant_access_name.png'
import I_AM_IMG from '@assets/images/cloud_monitor/i_am.png'
import MICROSOFT_SECURITY_IMG from '@assets/images/cloud_monitor/microsoft_security.png'
import REGISTER_APPLICATION_IMG from '@assets/images/cloud_monitor/register_application.png'
import SAVE_KEY_IN_JSON_IMG from '@assets/images/cloud_monitor/save_key_in_json.png'
import UPLOAD_CREDENTIALS_IMG from '@assets/images/cloud_monitor/upload_credentials.png'
import DataLoading from '@assets/images/data-loading.svg'
import EMPTY_SVG from '@assets/images/empty.svg'
import EMPTY_DATA_SVG from '@assets/images/empty_data.svg'
import GLOBE_PNG from '@assets/images/globe.png'
import INTROSPECT_DOLLAR_BG_SVG from '@assets/images/introspect-dollar-bg.svg'
import INTROSPECT_RUPEE_BG_SVG from '@assets/images/introspect-rupee-bg.svg'
import { ReactComponent as LinuxLogoSvg } from '@assets/images/linux.svg'
import LogoSmallWhiteSvg from '@assets/images/logo_small_white.svg'
import { ReactComponent as MsWindowsLogoSvg } from '@assets/images/microsoft.svg'
import { ReactComponent as MobileSvg } from '@assets/images/mobile.svg'
import MONEY_BAG_SVG from '@assets/images/money_bag.svg'
import QuizSvg from '@assets/images/monitor_illustration.svg'
import { ReactComponent as NoMandatesSVG } from '@assets/images/no-mandates.svg'
import { ReactComponent as ThreeColumnSVG } from '@assets/images/pdfcol1.svg'
import { ReactComponent as TwoEqualColumnSVG } from '@assets/images/pdfcol2.svg'
import { ReactComponent as LeftBiggerTwoColumnSVG } from '@assets/images/pdfcol3.svg'
import { ReactComponent as RightBiggerTwoColumnSVG } from '@assets/images/pdfcol4.svg'
import { ReactComponent as FullWidthColumnSVG } from '@assets/images/pdfcol5.svg'
import REPORT from '@assets/images/report.svg'
import REPORT2 from '@assets/images/report2.svg'
import { ReactComponent as RobotSvg } from '@assets/images/robot.svg'
import SHEILD_SVG from '@assets/images/sheild.svg'
import SHEILD_HOLLOW_SVG from '@assets/images/sheild_hollow.svg'
import { ReactComponent as SHEILD_HOLLOW_SVG_OUTLINE } from '@assets/images/sheild_hollow1.svg'
import SETUP_WIZARD_SHIELD_CONTAINER from '@assets/images/shield_container_setup_wizard.svg'
import SETUP_WIZARD_SHIELD_BG, { ReactComponent as SetupWizardSvgFill } from '@assets/images/shield_setup_wizard.svg'
import { ReactComponent as SetupWizardSvgFillWave } from '@assets/images/shield_setup_wizard_wave.svg'
import SLACK_CONNECTED_SVG from '@assets/images/slack_connected.svg'
import SLACK_NOT_CONNECTED_SVG from '@assets/images/slack_not_connected.svg'
import SPEEDOMETER_BAR from '@assets/images/speedometer_bar.svg'
import SPEEDOMETER_NEEDLE from '@assets/images/speedometer_needle.svg'
import { ReactComponent as StackSvg } from '@assets/images/stack.svg'
import { ReactComponent as UbuntuLogoSvg } from '@assets/images/ubuntu.svg'
import UPGRADE from '@assets/images/upgrade.svg'
import USERPERMISSION_SVG from '@assets/images/user_permission.svg'

import WAVE_SVG from '@assets/images/wave.svg'
import WAVE2_SVG from '@assets/images/wave2.svg'
import { ReactComponent as WebSvg } from '@assets/images/web.svg'

const IMAGES_URL = `${import.meta.env.VITE_APP_STATIC_ASSETS_URL}/images`
const ICONS_URL = `${import.meta.env.VITE_APP_STATIC_ASSETS_URL}/icons`
const isOnPrem = import.meta.env.VITE_APP_ENABLE_ONPREM === 'true'
export const images = {
	DataLoading,
	CloudConfig,
	EMPTY_DATA_SVG,
	SHEILD_HOLLOW_SVG_OUTLINE,
	EMPTY_SVG: isOnPrem ? EMPTY_SVG : `${IMAGES_URL}/empty.svg`,
	INTROSPECT_RUPEE_BG_SVG,
	INTROSPECT_DOLLAR_BG_SVG,
	MONEY_BAG_SVG: isOnPrem ? MONEY_BAG_SVG : `${IMAGES_URL}/money_bag.svg`,
	REPORT: isOnPrem ? REPORT : `${IMAGES_URL}/report.svg`,
	REPORT2: isOnPrem ? REPORT2 : `${IMAGES_URL}/report2.svg`,
	SHEILD_SVG: isOnPrem ? SHEILD_SVG : `${IMAGES_URL}/sheild.svg`,
	SHEILD_HOLLOW_SVG: isOnPrem ? SHEILD_HOLLOW_SVG : `${IMAGES_URL}/sheild_hollow.svg`,
	SPEEDOMETER_BAR: isOnPrem ? SPEEDOMETER_BAR : `${IMAGES_URL}/speedometer_bar.svg`,
	SPEEDOMETER_NEEDLE: isOnPrem ? SPEEDOMETER_NEEDLE : `${IMAGES_URL}/speedometer_needle.svg`,
	WAVE_SVG: isOnPrem ? WAVE_SVG : `${IMAGES_URL}/wave.svg`,
	WAVE2_SVG: isOnPrem ? WAVE2_SVG : `${IMAGES_URL}/wave2.svg`,
	GLOBE_PNG,
	SETUP_WIZARD_SHIELD_CONTAINER,
	SETUP_WIZARD_SHIELD_BG,
	UPGRADE,
	SLACK_CONNECTED_SVG,
	SLACK_NOT_CONNECTED_SVG,
	QuizSvg,
	ACCESSKEY_IMG,
	USERPERMISSION_SVG,
	AWS_ADD_CREDENTIAL_SVG,
	GRANT_ACCESS_NAME_IMG,
	GENERATE_KEY_IMG,
	SAVE_KEY_IN_JSON_IMG,
	UPLOAD_CREDENTIALS_IMG,
	MICROSOFT_SECURITY_IMG,
	REGISTER_APPLICATION_IMG,
	GENERATE_CLIENT_SECRET_IMG,
	I_AM_IMG,
	ADD_AZURE_CREDENTIALS_IMG,
	RISK_MATRIX
}

// Usage = <SvgIcons.WebSvg />
export const SvgIcons = {
	BriefcaseSvg,
	FireSvg,
	CogSvg,
	ToolsSvg,
	BankIconSvg,
	LogoSmallWhiteSvg,
	WebSvg,
	MobileSvg,
	StackSvg,
	AndroidSvg,
	ThreeColumnSVG,
	FullWidthColumnSVG,
	TwoEqualColumnSVG,
	LeftBiggerTwoColumnSVG,
	RightBiggerTwoColumnSVG,
	EmployeeSvg,
	BankSvg,
	DollarSvg,
	HospitalSvg,
	LocationSvg,
	MoneySvg,
	VendorsSvg,
	MsWindowsLogoSvg,
	LinuxLogoSvg,
	AppleLogoSvg,
	UbuntuLogoSvg,
	VendorVECTOR,
	VendorICON,
	VendorSUCCESS,

	DBSvg,
	DBCheckSvg,
	DBDefinitionSvg,
	ApiSvg,
	SetupWizardSvgFill,
	SetupWizardSvgFillWave,
	StarSvg,

	OsLogoGroupSvg,
	RobotSvg,
	LineSvg,
	DesktopDownloadSvg,
	SetupSvg,
	NoMandatesSVG,
	SpiderSVG,
	CheckSVG,
	WarningSVG,
	CancelSVG,
	ClockSVG,
	FilterSVG,
	TableGroupSVG,
	TableMenuSVG,
	HOST_PC_SVG,
	HIDE_COL_SVG,
	DOWNLOAD_SVG,
	Export_SVG,

	DASHBOARD_SVG,
	SECURITYOVERVIEW_SVG,
	CLOUD_SVG,
	HACKER_SVG,
	COMPLIANCE_SVG,
	MandateSvg,
	ADMINISTRATION_SVG,
	MANAGEMENT_SVG,
	DEV_BUG_SVG,
	HELP_SVG
}

export const icons = {
	LINUX_LIGHT_SVG,
	WINDOWS_LIGHT_SVG,
	APPLE_LIGHT_SVG,
	APPLE_SVG,
	ARROW_SVG,
	DEV_BUG_SVG: `${ICONS_URL}/dev bug.svg`,
	ERROR_SVG: isOnPrem ? ERROR_SVG : `${ICONS_URL}/error_graph.svg`,
	FEATURE_REQ_SVG: isOnPrem ? FEATURE_REQ_SVG : `${ICONS_URL}/feature req.svg`,
	GREEN_CUBE_SVG: isOnPrem ? GREEN_CUBE_SVG : `${ICONS_URL}/green_cube.svg`,
	HACKER2_SVG: isOnPrem ? HACKER2_SVG : `${ICONS_URL}/hacker2.svg`,
	HELP_SVG: `${ICONS_URL}/help.svg`,
	HOURGLASS_SVG: isOnPrem ? HOURGLASS_SVG : `${ICONS_URL}/hourglass.svg`,
	INFO_SVG: isOnPrem ? INFO_SVG : `${ICONS_URL}/info.svg`,
	INTERVAL_SVG: isOnPrem ? INTERVAL_SVG : `${ICONS_URL}/interval.svg`,
	// MAP_GREEN_DOT:isOnPrem?MAP_GREEN_DOT: `${ICONS_URL}/map_green_dot.svg`,

	// TODO: upload into `ICONS_URL`
	MAP_AMBER_DOT: MapMarkerAmberSvg,
	MAP_RED_DOT: MapMarkerRedSvg,
	MAP_GREEN_DOT: MapMarkerGreenSvg,

	WEEKLY_SVG: isOnPrem ? WEEKLY_SVG : `${ICONS_URL}/monthly.svg`,
	OTHER_SVG: isOnPrem ? OTHER_SVG : `${ICONS_URL}/other.svg`,
	SYNC_PNG: isOnPrem ? SYNC_PNG : `${ICONS_URL}/sync.png`,
	USER_SVG: isOnPrem ? USER_SVG : `${ICONS_URL}/user.svg`,
	UX_BUG_SVG: isOnPrem ? UX_BUG_SVG : `${ICONS_URL}/UX bug.svg`,
	MONTHLY_SVG: isOnPrem ? MONTHLY_SVG : `${ICONS_URL}/week.svg`,
	WHITE_CUBE_SVG: isOnPrem ? WHITE_CUBE_SVG : `${ICONS_URL}/white_cube.svg`,

	DOCUMENT_ERROR_ICON: DOCUMENT_ERROR_ICON,
	FIREWALL_SVG,
	INIT_SVG,
	INSTALL_SVG,
	LINUX_SVG,
	WINDOWS_SVG,
	BADGE_PNG,
	PC_SVG,
	SPARK_SVG,
	SPARK2_SVG,
	CHECK_CIRCLE_SVG,
	OUTLINED_CIRCLE_SVG,
	ActiveIntegration_SVG,
	SoftwareIntegration_SVG,
	Applicable_SVG,
	Not_Applicable_SVG,
	Azure_SVG,
	Gcp_SVG,
	Aws_SVG
}
