import { createSlice } from '@reduxjs/toolkit'
import { getSecurityFacts } from './reducer'

const initialState = ['Please wait while we load things up.']

export const securityFactsSlice = createSlice({
	name: 'securityFacts',
	initialState,
	extraReducers: {
		[getSecurityFacts.fulfilled]: (_, action) => action.payload
	}
})

export const securityFactsReducer = securityFactsSlice.reducer
