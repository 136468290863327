import Button from '@commons/Button/Button'
import adminRoutesConstant from '@constants/adminRoutesConstant'
import { permissionKeys } from '@constants/permissions'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import MenuIcon from '@mui/icons-material/Menu'
// import TranslateIcon from '@mui/icons-material/Translate'
import { AppBar, Box, Grid, IconButton, TextField, Toolbar } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { getCrtiticalityMatrix, updateCriticalitMatrix } from '@services/VRMService'
import { ASCENTS } from '@styles/theme'
import { images } from '@utils/icons'
import { enqueueSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import AppModal from '../Modal/AppModal'
import Text from '../Text'
const xorDEcrypt = (data, key) => {
	let encryptedData = ''
	if (data) {
		for (let i = 0; i < data.length; i++) {
			encryptedData += String.fromCharCode(data.charCodeAt(i) ^ key.charCodeAt(i % key.length))
		}
		return encryptedData
	} else {
		return null
	}
}

const Navbar = ({
	title,
	openSidebar,
	handleToggleSidebar
	// dateRangePickerProps,
	// handleTimeFrameChange,
	// selectedTimeFrame,
	// filtersByTime
}) => {
	const policies = useSelector((state) => state.auth.user.info?.role?.policy_list)
	const policiesIndexedByRouteName = policies?.reduce((acc, policy) => {
		acc[policy.RouteName] = policy
		return acc
	}, {})
	const [modalOpen, setModalOpen] = useState(false)
	const [criticalityMatrixData, setCrticalityMatrixData] = useState([])
	const history = useNavigate()
	const location = useLocation()
	const userRedux = useSelector((state) => state.auth.user.info?.role?.policy_list)
	let routeDetails = []

	const criticalLevels = [
		{
			id: 'low',
			label: 'Low',
			low: 'low_criticality_lower_range',
			high: 'low_criticality_upper_range'
		},
		{
			id: 'medium',
			label: 'Medium',
			low: 'medium_criticality_lower_range',
			high: 'medium_criticality_upper_range'
		},
		{
			id: 'high',
			label: 'High',
			low: 'high_criticality_lower_range',
			high: 'high_criticality_upper_range'
		},
		{
			id: 'critical',
			label: 'Critical',
			low: 'critical_criticality_lower_range',
			high: 'critical_criticality_upper_range'
		}
	]

	// const handleChange = (event, key) => {
	// 	if (key.length > 0) {
	// 		setCrticalityMatrixData(prevData => ({
	// 			...prevData,
	// 			[key]: parseFloat(event.target.value), // Assuming key is the identifier for low/high
	// 			[key === 'medium_criticality_lower_range' ? 'low_criticality_upper_range' : key === 'high_criticality_lower_range' ? 'medium_criticality_upper_range' : key === 'critical_criticality_lower_range' ? 'high_criticality_upper_range' : '']: parseFloat(event.target.value) > 0 ? parseFloat(event.target.value) - 0.01 : parseFloat(event.target.value),
	// 			[key === 'medium_criticality_upper_range' ? 'high_criticality_lower_range' : key === 'high_criticality_upper_range' ? 'critical_criticality_lower_range' : key === 'low_criticality_upper_range' ? 'medium_criticality_lower_range' : '']: parseFloat(event.target.value) < 10 ? parseFloat(event.target.value) + 0.01 : parseFloat(event.target.value)
	// 		}))
	// 	}
	// };

	const handleChange = (event) => {
		const { name, value } = event.target
		const newValue = parseFloat(value)
		if (newValue <= 10) {
			// Ensure the value does not exceed 10
			setCrticalityMatrixData((prevData) => ({
				...prevData,
				[name]: newValue,
				// Adjust adjacent values accordingly
				[name === 'medium_criticality_lower_range'
					? 'low_criticality_upper_range'
					: name === 'high_criticality_lower_range'
						? 'medium_criticality_upper_range'
						: name === 'critical_criticality_lower_range'
							? 'high_criticality_upper_range'
							: '']: newValue > 0 ? newValue - 0.01 : newValue,
				[name === 'medium_criticality_upper_range'
					? 'high_criticality_lower_range'
					: name === 'high_criticality_upper_range'
						? 'critical_criticality_lower_range'
						: name === 'low_criticality_upper_range'
							? 'medium_criticality_lower_range'
							: '']: newValue < 10 ? newValue + 0.01 : newValue
			}))
		}
	}

	userRedux?.forEach((item) => {
		if (adminRoutesConstant.adminPaths[item.RouteName]?.url()) {
			routeDetails.push({ routeName: item.RouteName, url: adminRoutesConstant.adminPaths[item.RouteName]?.url() })
		}
	})
	routeDetails = routeDetails.filter((route) => route.url.split('/').length === 2)
	const screenOrder = [
		'DashboardScreen',
		'ProcessVRM',
		'ManageVendor',
		'AdministrationOverView',
		'MasterFeed',
		'MasterQuestionnaire',
		'ChecklistScreen'
	]
	const orderedRoutes = routeDetails.sort((a, b) => {
		return screenOrder.indexOf(a.routeName) - screenOrder.indexOf(b.routeName)
	})

	const currentPath = location.pathname

	const goBack = () => {
		if (currentPath !== orderedRoutes[0]?.url) {
			const searchParams = new URLSearchParams(location.search)
			const token = searchParams.get('token')

			if (xorDEcrypt(token, 'MySecretKey') === null) {
				setTimeout(() => {
					window.location.replace('/process')
				}, 1000)
				return
			}

			const { templateID, StepID, FormID } = JSON.parse(xorDEcrypt(token, 'MySecretKey'))
			if (currentPath === '/process-criticality' || (currentPath === '/process-terminate' && templateID)) {
				console.log('GO BACK HIT')
				const newSearchParams = new URLSearchParams()
				newSearchParams.append('templateID', templateID)
				newSearchParams.append('stepId', StepID)
				newSearchParams.append('formId', FormID)
				history({ pathname: '/process', search: newSearchParams.toString() })
			} else {
				console.log('GO BACK HIT 1')
				history(-1)
			}
		}
	}

	const fetchCrticalityMatrix = () => {
		const criticalityMatrix = getCrtiticalityMatrix()
		criticalityMatrix.api().then((response) => {
			if (!response.error) {
				setCrticalityMatrixData(response)
			}
		})
	}

	React.useEffect(() => {
		fetchCrticalityMatrix()
	}, [])

	React.useEffect(() => {
		if (modalOpen) {
			fetchCrticalityMatrix()
		}
	}, [modalOpen])

	const toggleModal = () => {
		setCrticalityMatrixData([])
		setModalOpen(!modalOpen)
	}

	const setCrticalityMatrix = () => {
		setModalOpen(true)
	}

	const isMatrixValid = (jsonTemp) => {
		const json = Object.fromEntries(Object.entries(jsonTemp).filter(([key]) => key !== ''))
		const allValues = Object.entries(json).flatMap(([key, value]) =>
			key !== 'id' ? (Array.isArray(value) ? value : typeof value === 'object' ? Object.values(value) : value) : []
		)
		const isValidArray = allValues.every(
			(num, index) => index === 0 || (num > allValues[index - 1] && allValues.indexOf(num) === index)
		)
		console.log('isValidArray', isValidArray)
		return isValidArray
	}

	const handleSubmit = () => {
		if (Object.entries(criticalityMatrixData).length > 1) {
			const payload = Object.entries(criticalityMatrixData).reduce((acc, [key, value]) => {
				if (key) {
					acc[key] = value
				}
				return acc
			}, {})

			payload['critical_criticality_upper_range'] = 10
			if (isMatrixValid(criticalityMatrixData)) {
				const updateMatrix = updateCriticalitMatrix()
				updateMatrix
					.api({
						data: payload
					})
					.then((response) => {
						if (!response.error) {
							toggleModal()
							enqueueSnackbar(`Criticality Matrix changed successfully!!!`, { variant: 'success' })
							setTimeout(() => {
								window.location.reload()
							}, [500])
						}
					})
			} else {
				enqueueSnackbar(`The range selected is not valid.Kindly recheck!!!`, { variant: 'error' })
			}
		} else {
			enqueueSnackbar('Please Fill the matrix Table!!!', { variant: 'error' })
		}
	}

	return (
		<>
			<AppBar
				position='fixed'
				sx={{
					zIndex: (theme) => theme.zIndex.drawer + 1,
					transition: (theme) =>
						theme.transitions.create(['width', 'margin'], {
							easing: (theme) => theme.transitions.easing.sharp,
							duration: (theme) => theme.transitions.duration.leavingScreen
						}),
					backgroundColor: (theme) => theme.palette.ascents.white,
					padding: (theme) => theme.spacing(0, 2),
					boxShadow: 'none !important',

					position: 'sticky',
					top: 0,

					'& .MuiToolbar-regular': {
						// padding: "1.75rem",
						padding: '1.3755rem 0 !important',

						minHeight: 'inherit !important'
					},

					marginLeft: openSidebar ? 0 : undefined,
					width: openSidebar ? '100%' : undefined
				}}
			>
				<Toolbar>
					<Box flex='1 0 auto' display='flex' mr={2} alignItems='center'>
						{/* {
                        !sidebarIsOpen &&
                    } */}
						<IconButton
							onClick={handleToggleSidebar}
							sx={{
								color: (theme) => `${theme.palette.ascents.base} !important`
							}}
						>
							<MenuIcon />
						</IconButton>
						<Text fontSize='1.25rem !important' ascent='base' fontWeight={`500 !important`}>
							{title ? title : ''}
						</Text>
					</Box>

					{/* {currentPath !== '/process-terminate' && ( */}
					{
						currentPath === orderedRoutes[0]?.url && (
							<>
								{policiesIndexedByRouteName && policiesIndexedByRouteName[permissionKeys.CreateCriticalityMatrix] && (
									<Box
										sx={{
											'& .MuiIconButton-root': {
												boxShadow:
													'0px 0px 4px -1px rgb(188 193 221 / 15%), 0px 1px 8px 2px rgb(188 193 221 / 25%), 0px 0px 10px 1px rgb(188 193 221 / 20%)',
												// boxShadow:"0px 4px 10px 5px rgba(188, 193, 221, 0.5)",
												borderRadius: '0.75rem',
												padding: (theme) => theme.spacing(1)
											},

											'& img': {
												width: '2rem',
												height: '2rem'
											},

											'& .MuiSvgIcon-root': {
												color: (theme) => theme.palette.ascents.base
											}
										}}
										mr={2}
									>
										<IconButton onClick={setCrticalityMatrix}>
											{/* <SpeedIcon /> */}
											<img width={'100%'} height={'100%'} alt='risk-icon' src={images.RISK_MATRIX} />
										</IconButton>
									</Box>
								)}
							</>
						)
					}

					<Box
						sx={{
							'& .MuiIconButton-root': {
								boxShadow:
									'0px 0px 4px -1px rgb(188 193 221 / 15%), 0px 1px 8px 2px rgb(188 193 221 / 25%), 0px 0px 10px 1px rgb(188 193 221 / 20%)',
								// boxShadow:"0px 4px 10px 5px rgba(188, 193, 221, 0.5)",
								borderRadius: '0.75rem',
								padding: (theme) => theme.spacing(1.5)
							},

							'& img': {
								width: '2rem',
								height: '2rem'
							},

							'& .MuiSvgIcon-root': {
								color: (theme) => theme.palette.ascents.base
							}
						}}
						mr={2}
					>
						<IconButton onClick={goBack} disabled={currentPath === orderedRoutes[0]?.url}>
							<ArrowBackIcon />
						</IconButton>
					</Box>
					{/* <Box
						sx={{
							'& .MuiIconButton-root': {
								boxShadow:
									'0px 0px 4px -1px rgb(188 193 221 / 15%), 0px 1px 8px 2px rgb(188 193 221 / 25%), 0px 0px 10px 1px rgb(188 193 221 / 20%)',
								// boxShadow:"0px 4px 10px 5px rgba(188, 193, 221, 0.5)",
								borderRadius: '0.75rem',
								padding: (theme) => theme.spacing(1.5)
							},

							'& img': {
								width: '2rem',
								height: '2rem'
							},

							'& .MuiSvgIcon-root': {
								color: (theme) => theme.palette.ascents.base
							}
						}}
						mr={2}
					>
						<IconButton >
							<TranslateIcon />
						</IconButton>
					</Box> */}
				</Toolbar>
			</AppBar>

			<AppModal
				onClose={toggleModal}
				open={modalOpen}
				hideClose={true}
				title={'Criticality Severity Spectrum Scale'}
				showOverflowContent
				contentProps={{
					sx: {
						overflowY: 'visible',
						maxWidth: '45vw'
					}
				}}
				showActions
				ActionComponent={
					<Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1, padding: '0rem 1rem 1rem 0' }}>
						<Button variant='text' onClick={handleSubmit}>
							Submit
						</Button>
						<Button variant='text' color='secondary' onClick={toggleModal}>
							Close
						</Button>
					</Box>
				}
			>
				{/* <Text display={'flex'} justifyContent={'center'}>Please enter the values of the scoring from 0 - 10</Text> */}
				<Grid container spacing={3}>
					<Grid item xs={12} sm={8} md={9} lg={10} xl={10}>
						<Grid container ml={4} mt={4} display={'flex'}>
							{criticalLevels.map((item, index) => (
								<Grid item xs={12} key={index} display={'flex'} justifyContent={'space-between'}>
									<Grid xs={4} padding={2}>
										<Box>
											<Text
												fontSize='1rem'
												isCustomColor={true}
												fontFamily={'Open sans'}
												fontWeight={600}
												ascent={
													index === 0
														? ASCENTS.green
														: index === 1
															? ASCENTS.amber
															: index === 2
																? ASCENTS.lightRed
																: index === 3
																	? ASCENTS.red
																	: ASCENTS.lightBlue
												}
											>
												{item.label}
											</Text>
										</Box>
									</Grid>
									<Grid xs={8} display={'flex'}>
										<Box mr={2}>
											<FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
												<TextField
													name={item.low}
													id={`low-${index}`}
													label='Low'
													type='number'
													size='small'
													// InputProps={{
													// 	style:{
													// 		maxWidth:'7rem'
													// 	},
													// 	readOnly: item.high === 'low_criticality_lower_range' ? true : false,
													// 	inputProps: { min: 0, max: criticalityMatrixData[item.high] + 0.01 }
													// }}
													InputProps={{
														style: {
															maxWidth: '7rem'
														},
														readOnly: item.high === 'low_criticality_lower_range' ? true : false,
														inputProps: {
															min: 0,
															max: 10 // Ensure max value is 10
														}
													}}
													value={parseFloat(criticalityMatrixData[item.low]).toFixed(2) || 0}
													onChange={(event) => handleChange(event, item.low)}
												/>
											</FormControl>
										</Box>
										<Box mt={2}>
											<Text fontSize='1rem' isCustomColor={true} fontFamily={'Open sans'} fontWeight={600}>
												to
											</Text>
										</Box>
										<Box ml={2}>
											<FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
												<TextField
													id={`high-${index}`}
													label='High'
													name={item.high}
													type='number'
													size='small'
													// InputProps={{
													// 	readOnly: item.high === 'critical_criticality_upper_range' ? true : false,
													// 	inputProps: { min: criticalityMatrixData[item.low] + 0.01, max: 10 },
													// 	style: {
													// 		maxWidth:'7rem',
													// 		border: (item.high != 'critical_criticality_upper_range') && parseFloat(criticalityMatrixData[item.high]).toFixed(2) < parseFloat(criticalityMatrixData[item.low]).toFixed(2) ? '1px solid red' : '1px solid transparent'
													// 	}
													// }}
													InputProps={{
														readOnly: item.high === 'critical_criticality_upper_range' ? true : false,
														inputProps: {
															min: criticalityMatrixData[item.low] + 0.01,
															max: item.id === 'critical' ? 10 : 10 // Ensure max value is 10
														},
														style: {
															maxWidth: '7rem',
															border:
																item.high != 'critical_criticality_upper_range' &&
																	parseFloat(criticalityMatrixData[item.high]).toFixed(2) <
																	parseFloat(criticalityMatrixData[item.low]).toFixed(2)
																	? '1px solid red'
																	: '1px solid transparent'
														}
													}}
													value={
														item.id === 'critical'
															? 10
															: parseFloat(criticalityMatrixData[item.high]).toFixed(2) || 0
													}
													onChange={(event) => handleChange(event, item.high)}
												/>
											</FormControl>
										</Box>
									</Grid>
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			</AppModal>
		</>
	)
}

export default Navbar
