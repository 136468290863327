import adminRoutesConstant from '@constants/adminRoutesConstant'
import { dependentPermissionsForRoutes, permissionKeys } from '@constants/permissions'
import BaseLayout from '@layouts/BaseLayout'
import LoadingScreen from '@layouts/LoadingScreen'
import { refreshUserData } from '@redux/auth/reducers'
import { getPolicyLists } from '@redux/policies/reducer'
import { getRoleLists } from '@redux/roles/reducer'
import PageNotFound from '@screens/PageNotFound'
import * as Sentry from '@sentry/react'
import React, { Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRoutes } from 'react-router-dom'

const AppRoutes = () => {
	const userEmail = useSelector((state) => state.auth.user.info.email)
	const auth = useSelector((state) => state.auth)
	const [routesToRender, setRoutesToRender] = React.useState([])
	const dispatch = useDispatch()
	const reloadToken = useSelector((state) => state.reloadToken)

	const { current: routes } = React.useRef(adminRoutesConstant.guardRoutes.filter((each) => !each.doNotShowOnDashboardLayout))

	const { current: authRoutes } = React.useRef(adminRoutesConstant.authRoutes)

	const { current: baseRoutes } = React.useRef(adminRoutesConstant.baseRoutes)
	const { current: unlicensedRoutes } = React.useRef(adminRoutesConstant.unlicensedRoutes)

	const { current: routesWithoutLayout } = React.useRef(
		adminRoutesConstant.guardRoutes.filter((each) => !!each.doNotShowOnDashboardLayout)
	)

	// console.log('State Format::', window.location.pathname)
	// console.log('State Format 1::', location.state)

	const userHasAdminPermission = useSelector((state) => state.auth?.policyByRouteName[permissionKeys.AdministrationScreen])

	// console.log('isPermissionForRoleByAdmin', userHasAdminPermission)
	const RenderRoute = (route) => {
		/**
			caseSensitive?: AgnosticNonIndexRouteObject["caseSensitive"];
			path?: AgnosticNonIndexRouteObject["path"];
			id?: AgnosticNonIndexRouteObject["id"];
			loader?: AgnosticNonIndexRouteObject["loader"];
			action?: AgnosticNonIndexRouteObject["action"];
			hasErrorBoundary?: AgnosticNonIndexRouteObject["hasErrorBoundary"];
			shouldRevalidate?: AgnosticNonIndexRouteObject["shouldRevalidate"];
			handle?: AgnosticNonIndexRouteObject["handle"];
			index?: false;
			children?: RouteObject[];
			element?: React.ReactNode | null;
			errorElement?: React.ReactNode | null;
		*/
		return {
			id: route.key,
			element: (
				<>
					<Suspense fallback={<LoadingScreen />}>{route.component}</Suspense>
				</>
			),
			errorElement: <PageNotFound />,
			path: route.url()
		}
	}

	const getRoutesToRender = () => {
		// console.log('Oken info', auth.token?.info)
		// console.log('Oken info', auth.user.info?.role)
		if (!!auth.token?.info && auth.user.info?.role) {
			const allowedRoutes = routes.map((each) => {
				// console.log('Dependency', dependentPermissionsForRoutes[each.routeName])
				// console.log('routeName', auth.policyByRouteName[each])
				const routeDependencyIsValid = dependentPermissionsForRoutes[each.routeName]?.find((each) => auth.policyByRouteName[each])

				// return auth.policyByRouteName[each.routeName] || routeDependencyIsValid
				return auth.policyByRouteName[each.routeName] || routeDependencyIsValid
					? each
					: {
							...each
							//component: <UnAuthorizedScreen />
					  }
			})

			// console.log({ allowedRoutes, routes })

			// console.log({ allowedRoutes });
			setRoutesToRender(
				auth.user.info.role
					? [
							{
								path: '/',
								element: <BaseLayout />,
								children: [
									...allowedRoutes.map(RenderRoute),

									{
										path: '*',
										element: <PageNotFound />,
										errorElement: <PageNotFound />
									}
								]
							},
							...routesWithoutLayout.concat(baseRoutes).map(RenderRoute)
					  ]
					: unlicensedRoutes.map(RenderRoute)
			)

			if (userHasAdminPermission) {
				dispatch(getRoleLists())
				dispatch(getPolicyLists())
			}
		} else {
			const allowedRoutes = authRoutes.concat(baseRoutes)

			// console.log({ allowedRoutes });
			setRoutesToRender(allowedRoutes.map(RenderRoute))
		}
	}

	React.useEffect(() => {
		if (reloadToken) {
			dispatch(refreshUserData())
		}
	}, [reloadToken])

	React.useEffect(() => {
		getRoutesToRender()
	}, [auth.token.info, auth.user.info?.role])

	React.useEffect(() => {
		Sentry.setUser({
			email: userEmail || 'Not logged in'
		})
	}, [userEmail])

	const elements = useRoutes([
		...routesToRender,

		{
			path: '*',
			element: <PageNotFound />,
			errorElement: <PageNotFound />
		}
	])

	return routesToRender.length > 0 ? elements : null
}

export default AppRoutes
