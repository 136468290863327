import { AxiosService } from '@interceptors/axiosInstance'

// export const verifyEmail = (config) => {
// 	return AxiosService.get('/rest/v1/mail/', {
// 		...config,
// 		checkToken: false
// 	})
// }

// export const signIn = (config) => {
// 	return AxiosService.post('/rest/v1/login/', {
// 		...config,
// 		checkToken: false
// 	})
// }

export const verifyEmail = (config) => {
	return AxiosService.post('/api/v1/auth/verify-email', {
		...config,
		checkToken: false
	})
}

export const setPasswordApi = (config) => {
	return AxiosService.post('/api/v1/auth/set-password', {
		...config,
		checkToken: false
	})
}

export const signIn = (config) => {
	return AxiosService.post('/api/v1/auth/login', {
		...config,
		checkToken: false
	})
}

export const signUp = (config) => {
	return AxiosService.post('/rest/v1/signup/', {
		...config,
		checkToken: false
	})
}

export const validateToken = (config) => {
	return AxiosService.get('/rest/v1/signup/prefill/', config)
}

export const sendLicenseKey = (config) => {
	return AxiosService.post('/rest/v1/license/activate/', config)
}

// export const getLoggedInUserDetails = (extraHeaders = {}) => {
// 	if (Object.keys(extraHeaders).length > 0) {
// 		return AxiosService.get('/rest/v1/me/', { headers: extraHeaders })
// 	}

// 	return AxiosService.get('/rest/v1/me/')
// }

const ensureCorrectAuthorizationFormat = (headers) => {
	if (headers.Authorization) {
		// Ensure there's only one space between 'Bearer' and the token
		const parts = headers.Authorization.trim().split(/\s+/)
		if (parts[0] === 'Bearer' && parts.length === 3) {
			// If there were two spaces, correct it
			headers.Authorization = `Bearer ${parts[2]}`
		}
	}
	return headers
}

export const getLoggedInUserDetails = (extraHeaders = {}) => {
	if (Object.keys(extraHeaders).length > 0) {
		return AxiosService.get('/api/v1/user/me', { headers: extraHeaders })
	}
	return AxiosService.get('/api/v1/user/me')
}

export const getLoggedInUserDetailsDirect = (token) => {
	let tokenString = `Bearer ${token}` // your_actual_token should be replaced by the actual token
	let headers = {
		Authorization: tokenString
	}
	headers = ensureCorrectAuthorizationFormat(headers)
	console.log('This is final headers', headers)
	return AxiosService.get('/api/v1/user/me', { headers })
}

export const updateUserDetails = (config) => {
	return AxiosService.put('/rest/v1/me/', config)
}

export const updateUserProfileDetails = (id, config) => {
	return AxiosService.put(`/api/v1/user/update-user/${id}`, config)
}

export const refreshCurrentToken = (config) => {
	return AxiosService.post('/rest/v1/refresh-token/', {
		...config,
		checkToken: false
	})
}

export const logoutUser = (config) => {
	return AxiosService.get('/rest/v1/logout/', config)
}
