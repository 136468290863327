import { createSlice } from '@reduxjs/toolkit'
import { redirectSsoLoginUser, refreshUserData, updateSubscriptionDetails, userLogin } from './reducers'

const initialState = {
	token: {
		info: null,
		error: false,
		message: '',
		isLoading: false
	},
	user: {
		info: {},
		error: false,
		message: '',
		isLoading: false
	},
	allPolicyIds: [],
	policyById: {},
	policyByRouteName: {},
	allRouteNamesByPolicy: [],
	paidSubscriptionDetails: {
		info: null,
		error: false,
		message: '',
		isLoading: false
	}
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		resetUserState: () => initialState,
		logoutUser: () => initialState,
		updateUserToken: (state, action) => {
			state.token.info = action.payload.token
		}
	},
	extraReducers: {
		[userLogin.pending]: (state) => {
			state.token.error = false
			state.token.message = ''
			state.token.isLoading = true
		},
		[userLogin.fulfilled]: (state, action) => {
			state.token.isLoading = false
			state.token.info = action.payload
		},
		[userLogin.rejected]: (state, action) => {
			state.token.isLoading = false

			state.token.error = true

			state.token.info = null

			state.token.message =
				action.payload?.response?.data?.detail?.concat?.()?.pop?.() || 'We are  facing an issue right now. Please try again later.'
		},

		[redirectSsoLoginUser.pending]: (state) => {
			state.token.error = false
			state.token.message = ''
			state.token.isLoading = true
		},
		[redirectSsoLoginUser.fulfilled]: (state, action) => {
			state.token.isLoading = false
			state.token.info = action.payload
		},
		[redirectSsoLoginUser.rejected]: (state, action) => {
			state.token.isLoading = false

			state.token.error = true

			state.token.info = null

			state.token.message =
				action.payload?.response?.data?.detail?.concat?.()?.pop?.() || 'We are  facing an issue right now. Please try again later.'
		},

		[refreshUserData.pending]: (state) => {
			state.user.error = false
			state.user.message = ''
			state.user.isLoading = true
		},
		[refreshUserData.fulfilled]: (state, action) => {
			state.user.isLoading = false
			state.user.info = action.payload || {}

			if (action.payload?.role?.policies?.length > 0) {
				state.allPolicyIds = action.payload?.role?.policies
			}

			if (action.payload?.permissions?.permission_ids?.length > 0) {
				const policyById = {},
					policyByRouteName = {}
				const policyList = action.payload?.permissions?.permission_ids.concat()

				for (let index = 0; index < policyList.length; index++) {
					const item = policyList[index]

					policyById[item.id] = { ...item }
					policyByRouteName[item.route_name] = { ...item }
				}

				state.policyById = policyById
				state.policyByRouteName = policyByRouteName
				state.allRouteNamesByPolicy = Object.keys(policyByRouteName)
			}
		},
		[refreshUserData.rejected]: (state, action) => {
			state.token.isLoading = false

			state.token.error = true

			state.token.info = null

			state.token.message =
				action.payload?.response?.data?.detail?.concat?.()?.pop?.() || 'We are facing an issue right now. Please try again later.'
		},
		[updateSubscriptionDetails.pending]: (state) => {
			state.paidSubscriptionDetails.error = false
			state.paidSubscriptionDetails.message = ''
			state.paidSubscriptionDetails.isLoading = true
		},
		[updateSubscriptionDetails.fulfilled]: (state, action) => {
			state.paidSubscriptionDetails.isLoading = false
			state.paidSubscriptionDetails.info = action.payload
		},
		[updateSubscriptionDetails.rejected]: (state, action) => {
			state.paidSubscriptionDetails.isLoading = false

			state.paidSubscriptionDetails.error = true

			state.paidSubscriptionDetails.info = null

			state.paidSubscriptionDetails.message =
				action.payload?.response?.data?.detail?.concat?.()?.pop?.() || 'May be your subscription is expired'
		}
	}
})

export const { logoutUser, updateUserToken, resetUserState } = authSlice.actions

export const authReducer = authSlice.reducer
