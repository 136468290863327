import { Box } from '@mui/material'
import { fadeColor } from '@utils/styles'
import React from 'react'
import Text from '../Text'

const SidebarOrganisation = ({ organisationName = '', openSidebar }) => {
	// const [riskValue, setRiskValue] = React.useState(-1)
	//const loggedInOrgID = userRedux?.organization?.id
	const [riskData, setRiskData] = React.useState({
		type: '',
		reasons: [],
		ascent: 'base',
		icon: null,
		tooltipTitle: '',
		tooltipSubTitle: ''
	})

	// React.useEffect(() => {
	// 	if (riskValue <= riskValueByStatus.noData) {
	// 		setRiskData({
	// 			ascent: 'secondary',
	// 			tooltipTitle: `Calculating risk...`,
	// 			tooltipSubTitle: '',
	// 			icon: SvgIcons.ClockSVG,
	// 			type: 'Waiting',
	// 			reasons: []
	// 		})
	// 	} else if (riskValue <= riskValueByStatus.stable) {
	// 		setRiskData({
	// 			ascent: 'green',
	// 			tooltipTitle: `All systems stable for ${organisationName}`,
	// 			tooltipSubTitle: '',
	// 			icon: SvgIcons.CheckSVG,
	// 			type: 'Stable',
	// 			reasons: []
	// 		})
	// 	} else if (riskValue <= riskValueByStatus.medium) {
	// 		setRiskData({
	// 			ascent: 'lightAmber',
	// 			tooltipTitle: `Some systems might have some issues for ${organisationName}`,
	// 			tooltipSubTitle: '',
	// 			icon: SvgIcons.WarningSVG,
	// 			type: 'Medium',
	// 			reasons: []
	// 		})
	// 	} else {
	// 		setRiskData({
	// 			ascent: 'red',
	// 			tooltipTitle: `Critical issues have been found for ${organisationName}`,
	// 			tooltipSubTitle: '',
	// 			icon: SvgIcons.CancelSVG,
	// 			type: 'Critical',
	// 			reasons: []
	// 		})
	// 	}
	// }, [riskValue])

	// React.useEffect(() => {
	// 	const getAssetInformationAnalyticsApi = fetchOrganizationRisk()
	// 	return () => {
	// 		getAssetInformationAnalyticsApi.cancel()
	// 	}
	// }, [])

	return (
		// <Tooltip arrow title={riskData.tooltipTitle} placement={!openSidebar ? 'right' : 'top'}>
		<Box
			sx={{
				textAlign: 'center',
				background: (theme) => fadeColor(theme.palette.ascents[riskData.ascent], 0.8),

				borderRadius: (theme) => (!openSidebar ? 0 : theme.spacing(1)),
				padding: (theme) => theme.spacing(!openSidebar ? 2.25 : 1.25, 2.5),
				margin: !openSidebar ? '1rem 0' : '1rem 1.5rem',

				'& img': {
					color: (theme) => theme.palette.ascents.wash,
					textTransform: 'capitalize',
					fontFamily: '"Roboto", sans-serif',
					fontSize: 14
				}
			}}
			mx={2}
		>
			{openSidebar && (
				<Text
					fontFamily='"Open Sans", sans-serif'
					fontSize={10}
					fontWeight={100}
					ascent='greyBlue'
					variant='body1'
					textTransform='uppercase'
					letterSpacing={1}
				>
					Welcome
				</Text>
			)}
			<Box display='flex' justifyContent='center' alignItems='center'>
				{openSidebar && (
					<Text
						ascent='white'
						variant='body1'
						textTransform='uppercase'
						letterSpacing={1}
						textOverflow='ellipsis'
						overflow='hidden'
						whiteSpace='nowrap'
						margin='0 0.25rem 0 0'
					>
						{organisationName}
					</Text>
				)}
				{/* {riskData.icon && (
						<riskData.icon
							color={ASCENTS[riskData.ascent]}
							style={{
								width: 16,
								height: 16
							}}
						/>
					)} */}
			</Box>
		</Box>
		// {/* </Tooltip> */}
	)
}

export default SidebarOrganisation
